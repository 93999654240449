import React from "react";
import { View, StyleSheet, Text } from "react-native";
import {Button} from 'react-native-paper';
import Colors from '../../constants/Colors';
import {top, left, w, h} from '../Scale';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import evalExp from './EvalExp';
import {evalColor, evalVisibility, evalFontSize} from './AttribIndex';
import Svg, {
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
} from 'react-native-svg';
/* export default props => (
  
  <View style={{position: 'absolute', top: h(props.config._attr.top), left: w(props.config._attr.left), width:w(props.config._attr.width), height:h(props.config._attr.height)}}>
     <Button labelStyle={{alignItems:'center'}} mode="contained" color={props.config.children[1]._attr.backColor}>{props.config.children[1].children[0]._attr.caption}</Button>
  </View>
); */

const RectComponent = (props) => {
 
  const x=parseInt(props.config._attr.left) && parseInt(props.config._attr.width) ? parseInt(parseInt(props.config._attr.left) + parseInt(props.config._attr.width/2)) : null;
  const y=parseInt(props.config._attr.height) && parseInt(props.config._attr.top) ? parseInt(parseInt(props.config._attr.top) + parseInt(props.config._attr.height/2)) : null;
  //const points = props.config._attr.line.split(" ").map((p,i) => i%2==0?w(p):h(p)).join(' ');
  //const points = props.config._attr.path.split(" ").map((p,i)=>{i%2===0?`${p},`:`${p} `}).join('');
  
  return (
    <>

        <Svg>
        <Rect x={props.config._attr.left} 
        y={props.config._attr.top} 
        width={props.config._attr.width} 
        height={props.config._attr.height} 
        stroke={evalColor(props)!==-1?evalColor(props).foreColor1:props.config._attr.foreColor}
        strokeWidth={props.config._attr.lineWidth}
        fill={evalColor(props)!==-1?evalColor(props).backColor1:props.config._attr.backColor}/>
          
          {props.children}
        </Svg>
    </>
  );
};

export default RectComponent;

