// Forked 'toFloat' from https://gist.github.com/laerciobernardo/498f7ba1c269208799498ea8805d8c30
// Forked 'toHex' from stackoverflow answer https://stackoverflow.com/a/47187116/10522253
// Modifyed by: Jozo132 (https://github.com/Jozo132)
import React, { useRef, useContext, useState, useEffect  } from "react";

import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';


import { Context as GfxContext } from '../../context/GfxContext';
function isHex(h) {
  var re = /[0-9A-Fa-f]{6}/g;
return (re.test(h))
}




const Float32ToHex = (float32) => {
    const getHex = i => ('00' + i.toString(16)).slice(-2);
    var view = new DataView(new ArrayBuffer(4))
    view.setFloat32(0, float32);
    return Array.apply(null, { length: 4 }).map((_, i) => getHex(view.getUint8(i))).join('');
}

const Float32ToBin = (float32) => {
    const HexToBin = hex => (parseInt(hex, 16).toString(2)).padStart(32, '0');
    const getHex = i => ('00' + i.toString(16)).slice(-2);
    var view = new DataView(new ArrayBuffer(4))
    view.setFloat32(0, float32);
    return HexToBin(Array.apply(null, { length: 4 }).map((_, i) => getHex(view.getUint8(i))).join(''));
}

const HexToFloat32 = (str) => {
    var int = parseInt(str, 16);
    if (int > 0 || int < 0) {
        var sign = (int >>> 31) ? -1 : 1;
        var exp = (int >>> 23 & 0xff) - 127;
        var mantissa = ((int & 0x7fffff) + 0x800000).toString(2);
        var float32 = 0
        for (var i = 0; i < mantissa.length; i += 1) { float32 += parseInt(mantissa[i]) ? Math.pow(2, exp) : 0; exp-- }
        return float32 * sign;
    } else return 0
}

const BinToFloat32 = (str) => {
    var int = parseInt(str, 2);
    if (int > 0 || int < 0) {
        var sign = (int >>> 31) ? -1 : 1;
        var exp = (int >>> 23 & 0xff) - 127;
        var mantissa = ((int & 0x7fffff) + 0x800000).toString(2);
        var float32 = 0
        for (var i = 0; i < mantissa.length; i += 1) { float32 += parseInt(mantissa[i]) ? Math.pow(2, exp) : 0; exp-- }
        return float32 * sign;
    } else return 0
}

function convertNumArrayToHexString(list) {
    var result = [];
    for (var i = 0; i < list.length; i++) {
      if(list[i].toString().indexOf(".")==-1){
        if(i==2 && list[i]<256){
          result.push('00');
        }
        if(list[i].toString(16).length%2==0){
        result.push(list[i].toString(16).toUpperCase());
      }else{
        result.push('0'+list[i].toString(16).toUpperCase());
      }
      }else{
        result.push(Float32ToHex(list[i]).toUpperCase());
      }
    }
    return(result.join(""));
}








export {Float32ToHex, HexToFloat32, convertNumArrayToHexString};
// Full example
/* var test_value = -0.3;
console.log(`Input value (${test_value}) => hex (${Float32ToHex(test_value)}) [${Math.ceil(Float32ToHex(test_value).length / 2)} bytes] => float32 (${HexToFloat32(Float32ToHex(test_value))})`);
console.log(`Input value (${test_value}) => binary (${Float32ToBin(test_value)}) [${Float32ToBin(test_value).length} bits] => float32 (${BinToFloat32(Float32ToBin(test_value))})`); */

/* DEBUG OUTPUT:
  Input value (-0.3) => hex (be99999a) [4 bytes] => float32 (-0.30000001192092896)
  Input value (-0.3) => binary (10111110100110011001100110011010) [32 bits] => float32 (-0.30000001192092896)
*/