import React, { useContext } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { View, StyleSheet, Text, Platform } from 'react-native';
import {navigate} from '../navigationRef';
import { Input, Button } from 'react-native-elements';
import { Context as TrackContext } from '../context/TrackContext';
import EditForm from '../components/EditForm';
//import MapView, { Polyline } from 'react-native-maps';
if (Platform.OS !== 'web') {
  MapView = require('react-native-maps').default;
  Polyline = require('react-native-maps').Polyline;
}

const TrackDetailScreen = ({ route, navigation }) => {
  const { state, deleteTrack, updateTrackName } = useContext(TrackContext);
  const { _id } = route.params;
  const track = state.find(t => t._id === _id);
  let initialCoords;
  let trackName;
  if(track){
    initialCoords = track.locations[0].coords;
    trackName=track.name;
  }else{
    initialCoords=0;
    trackName='';
  }

  useFocusEffect(
    React.useCallback(() => {
      
      //alert('Screen was focused');
      // Do something when the screen is focused
      return () => {
        //navigate('TrackList');
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );


  return (
    <>
      <Text style={{ fontSize: 48 }}>{trackName}</Text>
      {Platform.OS !== 'web' && track &&
      <MapView
        initialRegion={{
          longitudeDelta: 0.01,
          latitudeDelta: 0.01,
          ...initialCoords
        }}
        style={styles.map}
      >
        <Polyline coordinates={track.locations.map(loc => loc.coords)} />
      </MapView>}

      <EditForm
        id={_id}
        headerText="Edit Track Name"
        onSubmit={updateTrackName}
        submitButtonText="Update"
        onDelete={deleteTrack}
        deleteButtonText="Delete"
      />
    </>
  );
};

const styles = StyleSheet.create({
  map: {
    height: 300
  }
});

export default TrackDetailScreen;
