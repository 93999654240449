import createDataContext from './createDataContext';
import trackerApi from '../api/tracker';
import {navigate} from '../navigationRef';
const gfxReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_file':
      //console.log("state:",state);
      //console.log("actionPayload",action.payload);
      let fileAlreadyExists = state.files.map(function(e) { return e.filename; }).indexOf(action.payload.filename) > -1;
      //console.log("fileIndex=",state.files.map(function(e) { return e.filename; }).indexOf(action.payload.filename));
      // make a copy of the existing array
      let files = state.files.slice();

      if(fileAlreadyExists) {
          files = files.filter(id => id.filename != action.payload.filename);                
      }     
          // modify the COPY, not the original
          files.push(action.payload);                 
		//console.log("fetch_file",state);
      return {
          // "spread" the original state object
          ...state,
          // but replace the "files" field
          files
      };

    case 'add_tags':
      let fileTagsExists = state.filetags.map(function(e) { return e.filename; }).indexOf(action.payload.filename) > -1;
      //console.log("fileIndex=",state.files.map(function(e) { return e.filename; }).indexOf(action.payload.filename));
      // make a copy of the existing array
      let filetags = state.filetags.slice();

      if(fileTagsExists) {
          filetags = filetags.filter(id => id.filename != action.payload.filename);                
      }     
          // modify the COPY, not the original
          filetags.push(action.payload);                 
		//console.log("add_tags",state);
      return {
          // "spread" the original state object
          ...state,
          // but replace the "files" field
          filetags
      };
	case 'set_tags':
		filetags=[];
		filetags.push(action.payload);  
		//console.log("set_tags",state);		
      return { ...state, filetags };
    case 'delete_tags':
		//console.log("delete_tags",state);
		if(state.activeFileName!==action.payload.filename){
		  return { ...state, filetags: state.filetags.filter(id => id.filename != action.payload.filename) };
		}else{
		  return state;
		}
	  
	case 'delete_file':
		//console.log("delete_file",state);
      return { ...state, files: state.files.filter(id => id.filename != action.payload.filename) };
	  
	case 'activate_file':
	//console.log("activate_file",state);
      return { ...state, activeFileName: action.payload.filename };
	case 'update_Time':
	//console.log("activate_file",state);
      return { ...state, LastRecTime: action.payload };
	  
	case 'add_Logic':
            let logicExists = state.logic.map(function(e) { return e.deviceID; }).indexOf(action.payload.deviceID) > -1;
      let logic = state.logic.slice();

      if(logicExists) {
          logic = logic.filter(id => id.deviceID != action.payload.deviceID);                
      }     
          logic.push(action.payload);                 
		//console.log("add_Logic",state);
      return {
          ...state,logic
      };
    case 'clear_file':
		//console.log("clear files", state);
		filetags=[];
		let activeFileName="";
      return { ...state, filetags, activeFileName };
	  
	case 'add_DBtags':
      let DBTagExists = state.tagDB.map(function(e) { return e.Tag; }).indexOf(action.payload.Tag) > -1;
      let tagDB = state.tagDB.slice();
      if(DBTagExists) {
          tagDB = tagDB.filter(id => id.Tag != action.payload.Tag);                
      }     
          tagDB.push(action.payload);    
			//console.log("tagDB",tagDB);
			//console.log("add_DBTags_payload",action.payload);
		//console.log("add_DBTags",state);
      return {...state,tagDB};
	  
    default:
      return state;
  }
};

const fetchFiles = dispatch => async (filename) => {
  try {
    console.log("filename:",filename);
    const response = await trackerApi.get('/file/'+filename);
  //console.log("filedata:",response.data.children[0].children);
    dispatch({ type: 'fetch_file', payload: {filename:filename, data:response.data.children} })
    
  }catch (err) {
    //console.log("filefetchingError:",err.response.data.error);
    console.log("filefetchingError");
    //navigate('loginFlow',{screen:'Signin'});
  }
};
const addFileTags = dispatch => async (filename, tags) => {
	//console.log("adding tags of file:",filename);
    dispatch({ type: 'add_tags', payload: {filename:filename, tags:tags} });
};
const setFileTags = dispatch => async (filename, tags) => {
	//console.log("setting tags of file:",filename);
    dispatch({ type: 'set_tags', payload: {filename:filename, tags:tags} });
		//console.log("Activating filename from setFileTags:",filename);
  dispatch({ type: 'activate_file', payload: {filename:filename} });
};
const deleteFileTags = dispatch => async (filename) => {
	//console.log("Deleting tags of file:",filename);
  dispatch({ type: 'delete_tags', payload: {filename:filename} });
};
const deleteFile = dispatch => async (filename) => {
  dispatch({ type: 'delete_file', payload: {filename:filename} });
};

const activateFile = dispatch => async (filename) => {
	//console.log("Activating filename:",filename);
  dispatch({ type: 'activate_file', payload: {filename:filename} });
};

const clearFiles = dispatch => async () => {
  try {
   
    dispatch({ type: 'clear_file', payload: [] })
    
  }catch (err) {
    console.log("clearError:");
    //navigate('loginFlow',{screen:'Signin'});
  }
};
const addDBTags = dispatch => async (tagPayload) => {
	//console.log("add_DBTags",tagPayload);
    dispatch({ type: 'add_DBtags', payload: tagPayload });
};
const addLogic = dispatch => async (logicPayload) => {
    dispatch({ type: 'add_Logic', payload: logicPayload });
};
const updateTime = dispatch => async (newTime) => {
	//console.log("add_DBTags",tagPayload);
    dispatch({ type: 'update_Time', payload: newTime });
};
export const { Provider, Context } = createDataContext(
  gfxReducer,
  { fetchFiles, clearFiles, addFileTags, deleteFileTags, deleteFile, activateFile, setFileTags, addDBTags, updateTime, addLogic },
  {files:[], filetags:[], activeFileName:"", LastRecTime:1605814928200, tagDB:[{"Tag":"[Room1]T","type":"R","Address":20,"value":"30.8","sent":1605814928200},{"Tag":"[Room1]L","type":"I","Address":0,"value":"3350","sent":1605814928200},{"Tag":"[Room1]V","type":"R","Address":8,"value":"30.8","sent":1605814928200},{"Tag":"[Room1]SetBool","type":"B","Address":43,"value":0,"sent":1605814928200},{"Tag":"[Room1]SetBool2","type":"B","Address":13,"value":0,"sent":1605814928200}], logic:[{"deviceID":1,"logic":"(XIO,M0)(OTE,M2)(SVI,DI0,DI6,DI10,ML40,ML44)!"}]}
);
