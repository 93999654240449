import createDataContext from './createDataContext';
import trackerApi from '../api/tracker';
import {navigate} from '../navigationRef';
const trackReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_tracks':
      return action.payload;
    case "delete_tracks":
      return state.filter( t  => t._id !== action.id);
    default:
      return state;
  }
};

const fetchTracks = dispatch => async () => {
  try {
    const response = await trackerApi.get('/tracks');
  //console.log("locdata:",response.data);
    dispatch({ type: 'fetch_tracks', payload: response.data })
    
  }catch (err) {
    console.log("fetchingError:",err.response.data.error);
    navigate('loginFlow',{screen:'Signin'});
  }
};
const createTrack = dispatch => async (name, locations) => {
  //console.log("trackcreateReq Sent",locations);
  await trackerApi.post('/tracks', { name, locations });
};
const deleteTrack = dispatch => async (id) => {
  console.log("trackDelete id",id);
  await trackerApi.delete('/tracks/'+id);
  dispatch({ type: 'delete_tracks',  id });
  navigate('TrackList');
};
const updateTrackName = dispatch => async (id, name) => {
  console.log("trackUpdate name",name);
  await trackerApi.put('/tracks/'+id+'/'+name);
  navigate('TrackList');
};

export const { Provider, Context } = createDataContext(
  trackReducer,
  { fetchTracks, createTrack, deleteTrack, updateTrackName },
  []
);
