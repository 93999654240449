import React, { useContext, useState } from 'react';
import { View, StyleSheet, Text, SafeAreaView } from 'react-native';
import {TextInput, Button} from 'react-native-paper';
import Colors from '../constants/Colors'
import { useFocusEffect } from '@react-navigation/native';
import trackerApi from '../api/tracker';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import Spacer from '../components/Spacer';
import { Context as AuthContext } from '../context/AuthContext';
//import io from 'socket.io-client';
import { navigate } from '../navigationRef';
//let socket=io();
let k="";
const AccountScreen = () => {
  const { signout, state } = useContext(AuthContext);
  const [value, onChangeText] = useState("");
 
  useFocusEffect(
    React.useCallback(() => {
      /* socket= io('https://infothrill.in?token='+state.token);
          socket.on('connect', () => {
            console.log("connected Hurray");
            setIsConnected(true);
          });
          // // on reconnection, reset the transports option, as the Websocket
          // // connection may have failed (caused by proxy, firewall, browser, ...)
          // socket.on('reconnect_attempt', () => {
          //   socket.io.opts.transports = ['polling', 'websocket'];
          // });
    
        socket.on("FromAPI", data => {
          console.log("FromAPI:",data);
          //let d=data.split('#').pop().split(';')[0];
            setResponse((response+data).replace(";", "\n"));
          //console.log("extracted data:",d);
        });
        socket.on('unauthorized', (error) => {
          console.log("unauth error yayyyy");
          if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
            // redirect user to login page perhaps or execute callback:
            navigate('loginFlow',{screen:'Signin'});
            //callback();
            console.log('User token has expired');
          }
        }); */
        
      //alert('Screen was focused');
      // Do something when the screen is focused
      return () => {
        /* socket.disconnect();
        console.log("Socket disconnected"); */
        //navigate('TrackList');
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );

/* const sendToAPI=()=>{
  console.log("ToAPI: ", value);
  //const socket = io('https://infothrill.in?token='+state.token);
  socket.emit("ToAPI", value);
} */

const SenderButton = (sendvalue) => {

  const sendToAPI=useEmitEvent('ToAPI');
  return (
    <>
      <Button labelStyle={{alignItems:'center'}} mode="contained" color={Colors.primary} onClick={() => sendToAPI(sendvalue.sendvalue)}>Send</Button>
    </>
  )
}
function hex_to_ascii(str1)
 {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
 }
 function ascii_to_hexa(str)
  {
	var arr1 = [];
	for (var n = 0, l = str.length; n < l; n ++) 
     {
		var hex = Number(str.charCodeAt(n)).toString(16);
		arr1.push(hex);
	 }
	return arr1.join('');
   }
const MsgDisplay = () => {
  const state = useSocketState();
  console.log(state);
  const fromapi=state.FromAPI;
  //const fromapi = useSocketSelector(state => state.FromAPI);
  console.log("FromAPI: ",fromapi);
  if(parseInt(fromapi.substring(2, 4),16)==9){
  k=k+hex_to_ascii(fromapi.substring(10));
  }else{
	k=fromapi;  
  }
  return (

	<p>{k}</p>
  );
}
  return (
    // <SafeAreaView forceInset={{ top: 'always' }}>
    <SafeAreaView style={styles.container}>
      <Text style={{ fontSize: 48 }}>AccountScreen</Text>
      <Text style={{ fontSize: 18 }}>{state.userEmail}</Text>
      <MsgDisplay />
      <Spacer>
    <View style={styles.sender}>
      <TextInput
      label='Type Message Here'
      onChangeText={text => onChangeText(text)}
      value={value}
    />
    <SenderButton sendvalue={value} />
    </View>
      </Spacer>
      <Spacer>
        <Button labelStyle={{alignItems:'center'}} mode="contained" color={Colors.primary} onPress={signout}>Sign Out</Button>
      </Spacer>
      <Spacer>
        <Button labelStyle={{alignItems:'center'}} mode="contained" color={Colors.primary} onPress = {() => navigate('mainFlow',{screen:'PanResponder'})}>
        Go to Pan Responder</Button>
      </Spacer>
    </SafeAreaView>
    
  );

};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems:'center',
    margin: 50
  },
  sender: {
    flex: 1,
    flexDirection:'row',
    justifyContent:'space-around'
  }
});

export default AccountScreen;
