import React, { useRef, useEffect, useState, useCallback, useMemo } from "react";
import * as d3 from 'd3';
import { View, StyleSheet, Text } from "react-native";



const freehandComp = (props) => {
  
  
  	const Line = ({ thickness, strokeColor, fillColor, points }) => {
		const line = (d) =>{
			return d3.line()
			.x((d) => d.x)
			.y((d) => d.y)
			.curve(d3.curveBasisOpen);
			
		};
		

		
		//console.log("freehand line:", line(points));
		return(
		<path d={line(points)}
		style={{
			stroke:strokeColor,
			strokeWidth: thickness,
			strokeLinejoin: "round",
			strokeLinecap: "round",
			fill: fillColor
		}}
		/>
		);
		
	};
  
  //const points = props.config._attr.line.split(" ").map((p,i) => i%2==0?w(p):h(p)).join(' ');
  //const points = props.config._attr.path.split(" ").map((p,i)=>{i%2===0?`${p},`:`${p} `}).join('');
  return (
    // <View style={{position: 'absolute', top: top(0), left: left(0)}}>
    <svg>

  <Line thickness={props.config._attr.lineWidth} strokeColor={props.config._attr.foreColor} fillColor={props.config._attr.backColor} points={props.config._attr.path} />
  {props.children}
</svg>

    // </View>
  );
};

export default freehandComp;

