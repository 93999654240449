import React, { useEffect, useContext } from 'react';
import { Context as AuthContext } from '../context/AuthContext';
import { Platform, StatusBar, StyleSheet, View, Text, SafeAreaView } from 'react-native';
import {Button} from 'react-native-paper';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  createDrawerNavigator,
  DrawerItemList
} from '@react-navigation/drawer';
import { DrawerContent } from './DrawerContent';
import TabBarIcon from '../components/TabBarIcon';
import AccountScreen from '../screens/AccountScreen';
import SigninScreen from '../screens/SigninScreen';
import SignupScreen from '../screens/SignupScreen';
import TrackCreateScreen from '../screens/TrackCreateScreen';
import TrackDetailScreen from '../screens/TrackDetailScreen';
import TrackListScreen from '../screens/TrackListScreen';
import GfxFileListScreen from '../screens/GfxFileListScreen';
import PanResponderScreen from '../screens/PanResponderScreen';
import DraggableListScreen from '../screens/DraggableListScreen';
import LogicScreen from '../screens/LogicScreen';
import Colors from '../constants/Colors';
import { navigate } from '../navigationRef';
import { Ionicons } from '@expo/vector-icons';
import trackerApi from '../api/tracker';
import {
  SocketProvider,
  useSocketState,
  useEmitEvent
} from 'react-socket-io-hooks';
let url=trackerApi;
const reducer = (state, action) => {
  switch(action.type) {
    case 'UPDATED_COUNT':
      return { ...state, count: action.payload};
    case 'FromAPI':
        return { ...state, FromAPI: action.payload };
    case 'TagData':
        return { ...state, TagData: action.payload };
    default:
      return state;
  }
}


const TrackStack= createStackNavigator();

function trackListFlow({ navigation, route }) {
  return (
    <TrackStack.Navigator headerMode="screen"
    screenOptions={{
      headerTintColor: Colors.tabIconSelected,
      headerStyle: { backgroundColor: Colors.primary },
      headerLeft: () => (
        <Button
            icon={
              <Ionicons
                name={Platform.OS === 'android' ? 'md-menu' : 'ios-menu'}
                size={45}
                color="white"
              />
            }
            onPress={() => {
              navigation.toggleDrawer();
            }}  
            mode="contained"
        >Menu</Button>
      ),
      headerRight: () => (
        <View style={{flex: 1, flexDirection:"row", alignItems:"center",}}>
        {/* <Button style={{margin:20, width:'40%'}}
          onPress={navigation.toggleDrawer}
          title="Information"
          color="#0ff"
          type="clear"
          mode="contained"
        >Info</Button> */}
        <Button style={{margin:10, flexWrap: 'wrap'}}
          onPress={() => navigate('Account')}
          title="Profile Info"
          color="#f00"
          type="clear"
          mode="contained"
        >Profile</Button>
        </View>
      ),
    }}>
            <TrackStack.Screen
        name="TrackList"
        component={TrackListScreen}
        options={{
          title: 'TrackList',
          headerTitleStyle:{textAlign:"center",flex:1},
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-list" />,
         
        }}
      />
      <TrackStack.Screen
        name="GfxFileList"
        component={GfxFileListScreen}
        options={{
          title: 'GfxFileList',
          headerTitleStyle:{textAlign:"center",flex:1},
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-list" />,
         
        }}
      />

       <TrackStack.Screen
        name="PanResponder"
        component={PanResponderScreen}
        options={{
          title: 'GFX',
          headerTitleStyle:{textAlign:"center",flex:1},
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-list" />,
         
        }}
      />
      
      <TrackStack.Screen
        name="TrackDetail"
        component={TrackDetailScreen}
        options={{
          title: 'TrackDetail',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-list" />,
        }}
      />
      </TrackStack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'Home';

function mainFlow({ navigation, route }) {
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  //navigation.setOptions({ headerTitle: getHeaderTitle(route) });
  

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      BottomTabBarOptions={{
        activeTintColor: '#e91e63',
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={trackListFlow}
        options={{
          BottomTabBarLabel: 'TrackList',
          BottomTabBarIcon: ({ color, size }) => (
            <TabBarIcon name="ios-list" color={color} size={size} />
          ),
        }}
      />
      {/* <BottomTab.Screen
        name="TrackCreate"
        component={TrackCreateScreen}
        options={{
          BottomTabBarLabel: 'Create Track',
          BottomTabBarIcon: ({ color, size }) => (
            <TabBarIcon name="ios-create" color={color} size={size} />
          ),
        }}
      /> */}
      <BottomTab.Screen
        name="Account"
        component={AccountScreen}
        options={{
          BottomTabBarLabel: 'Account Profile',
          BottomTabBarIcon: ({ color, size }) => (
            <TabBarIcon name="ios-person" color={color} size={size} />
          ),
        }}
      />
	        <BottomTab.Screen
        name="Logic"
        component={LogicScreen}
        options={{
          BottomTabBarLabel: 'Logic Profile',
          BottomTabBarIcon: ({ color, size }) => (
            <TabBarIcon name="ios-person" color={color} size={size} />
          ),
        }}
      />
     <BottomTab.Screen
        name="Test"
        component={DraggableListScreen}
        options={{
          BottomTabBarLabel: 'PanResponder',
          BottomTabBarIcon: ({ color, size }) => (
            <TabBarIcon name="ios-person" color={color} size={size} />
          ),
        }}
      /> 
    </BottomTab.Navigator>
  );
}

const TrackDrawerNavigator = createDrawerNavigator();

export const TrackNavigator = () => {
const { signout, state } = useContext(AuthContext);

  return (
    <SocketProvider
      uri={url}
      reducer={reducer}
      initialState={{count:0, FromAPI:'', TagData:[]}}>
    <TrackDrawerNavigator.Navigator
      drawerContent={props => {
        return (
          <View style={{ flex: 1, paddingTop: 20 }}>
            <SafeAreaView forceInset={{ top: 'always', horizontal: 'never' }}>
              <DrawerItemList {...props} />
              <DrawerContent {...props} />
              <Button
                mode="contained"
                title="Logout"
                color={Colors.primary}
                onPress={signout}
              >Logout</Button>
            </SafeAreaView>
          </View>
        );
      }}
      drawerContentOptions={{
        activeTintColor: Colors.primary
      }}
    >
      <TrackDrawerNavigator.Screen
        name="mainFlow"
        component={mainFlow}
        options={{
          drawerIcon: props => (
            <Ionicons
              name={Platform.OS === 'android' ? 'md-create' : 'ios-create'}
              size={23}
              color={props.color}
            />
          )
        }}
      />
      {/* <TrackDrawerNavigator.Screen
        name="TrackCreate"
        component={TrackCreateScreen}
        options={{
          drawerIcon: props => (
            <Ionicons
              name={Platform.OS === 'android' ? 'md-list' : 'ios-list'}
              size={23}
              color={props.color}
            />
          )
        }}
      /> */}
      <TrackDrawerNavigator.Screen
        name="GfxList"
        component={GfxFileListScreen}
        options={{
          drawerIcon: props => (
            <Ionicons
              name={Platform.OS === 'android' ? 'md-albums' : 'ios-albums'}
              size={23}
              color={props.color}
            />
          )
        }}
      />
      <TrackDrawerNavigator.Screen
        name="Account"
        component={AccountScreen}
        options={{
          drawerIcon: props => (
            <Ionicons
              name={Platform.OS === 'android' ? 'md-create' : 'ios-create'}
              size={23}
              color={props.color}
            />
          )
        }}
      />
    </TrackDrawerNavigator.Navigator>
    </SocketProvider>
  );
};
const LoginStack = createStackNavigator();

function loginFlow() {
  return (
    <LoginStack.Navigator
      initialRouteName="Home"
      headerMode="screen"
      screenOptions={{
        headerTintColor: Colors.tabIconSelected,
        headerStyle: { backgroundColor: Colors.primary, justifyContent: 'center', alignItems:'center' },
      }}
    >
      <LoginStack.Screen
        name="Signin"
        component={SigninScreen}
        options={{
          title: 'SignIn',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-log-in" />,
        }}
      />
      <LoginStack.Screen
        name="Signup"
        component={SignupScreen}
        options={{
          title: 'SignUp',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-contact" />,
        }}
      />
      </LoginStack.Navigator>
  );
}
const Stack = createStackNavigator();
const MainNavigator = () => {
    const { tryLocalSignin } = useContext(AuthContext);
    const { state, signin, clearErrorMessage } = useContext(AuthContext);

    useEffect(() => {
      //console.log("trackerApi=", trackerApi);
      //console.log("windowHref",window.location.href);

      tryLocalSignin();
    }, []);
    //url=window.location.href+'?token='+state.token;
	url='https://infothrill.in?token='+state.token;
    return(
    <Stack.Navigator headerMode="none"
    screenOptions={{
      headerTintColor: 'white',
      headerStyle: { backgroundColor: 'tomato' },
    }}>
    {state.token == null ? (
      // No token found, user isn't signed in
      <Stack.Screen
        name="SignIn"
        component={loginFlow}
        options={{
          title: 'Sign in',
        }}
      />
    ) : (
      // User is signed in
      <Stack.Screen name="Home" component={TrackNavigator} />
    )}
    <Stack.Screen name="mainFlow" component={mainFlow} />
    <Stack.Screen name="loginFlow" component={loginFlow} />
    {/* <Stack.Screen name="DrawerHome" component={TrackNavigator} /> */}
  </Stack.Navigator>
    );
  };
  
  export default MainNavigator;