import React from "react";
import { View, StyleSheet, Text } from "react-native";
import evalExp from './EvalExp';
export const animationIndex = (props) =>{
    if(typeof props.config.children!=="undefined"){
    return props.config.children.map((e)=>e.name).indexOf('animations');
    }else return -1;
  }
 export const animateColorIndex = (props) =>{
    if(animationIndex(props)>-1){
    if(typeof props.config.children[animationIndex(props)].children!=="undefined"){
    return props.config.children[animationIndex(props)].children.map((e)=>e.name).indexOf('animateColor');
    }else return -1;
  }else return -1;
  }
  export const evalColor = (props) =>{
    try{
    if(animateColorIndex(props)>-1){
        if(typeof props.config.children[animationIndex(props)].children[animateColorIndex(props)].children!=="undefined"){
      return props.config.children[animationIndex(props)].children[animateColorIndex(props)].children[props.config.children[animationIndex(props)].children[animateColorIndex(props)].children.map((e)=>e._attr.value).indexOf(evalExp(props.config.children[animationIndex(props)].children[animateColorIndex(props)]._attr.expression).toString())]._attr;
    }else {return -1;}
    }else {return -1;}
  }catch(e){
  //console.log(`${e} -text= ${props.config._attr.caption}`);
  return -1;
  }
  }
 
export const animateVisibilityIndex = (props) =>{
    if(typeof props.config.children!=="undefined"){
    return props.config.children[animationIndex(props)].children.map((e)=>e.name).indexOf('animateVisibility');
  }else {return -1;}
  }
  

export const roundN=(num,n) => {
    return parseFloat(Math.round(num * Math.pow(10, n)) /Math.pow(10,n)).toFixed(n);
  }
export const evalFontSize = (props) =>{
    if(typeof props.config._attr.fontSize!=="undefined" && typeof props.config._attr.caption==="undefined") {
        return props.config._attr.fontSize;
    }else if(typeof props.config._attr.caption!=="undefined"){
        //console.log("caption: ",props);
        return parseInt(Math.sqrt(props.config._attr.width*props.config._attr.height/(props.config._attr.caption).toString().length));
    }else if(typeof props.config._attr.charWidth!=="undefined" && typeof props.config._attr.charHeight!=="undefined" && typeof props.config._attr.fieldLength!=="undefined"){
        //console.log("charWidth: ",props);
        return Math.max(parseInt(Math.sqrt(props.config._attr.width*props.config._attr.charHeight/props.config._attr.fieldLength)), 5);
    }
	else {
		return 6;
	}
  }
export const evalVisibility = (props) =>{
  if(typeof props.config.children!=="undefined" && animationIndex(props)>-1 && animateVisibilityIndex(props)>-1){
    if(evalExp(props.config.children[animationIndex(props)].children[animateVisibilityIndex(props)]._attr.expression)===1){
      if(props.config.children[animationIndex(props)].children[animateVisibilityIndex(props)]._attr.expressionTrueState==="invisible"){
        return 0;
      }
      if(props.config.children[animationIndex(props)].children[animateVisibilityIndex(props)]._attr.expressionTrueState==="visible"){
        return 1;
      }
    }
    if(evalExp(props.config.children[animationIndex(props)].children[animateVisibilityIndex(props)]._attr.expression)===0){
      return 0;
    }
  }else {
    return 1;}
}
