import React from "react";
import Foo from "./GfxComponents/Foo";
import MainBackComp from "./GfxComponents/MainBack";
import ButtonComponent from "./GfxComponents/Button";
import RectComponent from "./GfxComponents/Rectangle";
import NumericDisplayComp from "./GfxComponents/NumericDisplay";
import { View, Text, TextComponent } from "react-native";
import LineComponent from "./GfxComponents/Line";
import GroupComponent from "./GfxComponents/Group";
import TextComp from "./GfxComponents/Text";
import PolylineComp from "./GfxComponents/Polyline";
import PolygonComp from "./GfxComponents/Polygon";
import freehandComp from "./GfxComponents/freehand";
import EllipseComp from "./GfxComponents/Ellipse";
import numericInputComp from "./GfxComponents/numericInput";
const KeysToComponentMap = {
  button: ButtonComponent,
  rectangle: RectComponent,
  numericDisplay: NumericDisplayComp,
  line: LineComponent,
  group: GroupComponent,
  polyline: PolylineComp,
  polygon: PolygonComp,
  freehand: freehandComp,
  ellipse: EllipseComp,
  numericInput: numericInputComp,
  text: TextComp,
  gfx: MainBackComp
};

/* export default block => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block
    });
  }
  return React.createElement(
    () => <View>The component {block.component} has not been created yet.</View>,
    { key: block._uid }
  );
}; */


function renderer(config) {
 
  if (typeof KeysToComponentMap[config.name] !== "undefined") {
    
        //console.log("component:",config)
    
     return React.createElement(
      KeysToComponentMap[config.name],
      {
        config: config
      },
        (typeof config.children !== "undefined")
          && config.children.map(c => renderer(c))
    ); 
  }else{
    //console.log("Undef",config);
  }
  
}

export default renderer;