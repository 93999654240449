import React, { useRef, useEffect, useState, useCallback, useMemo } from "react";
import * as d3 from 'd3'
import useWindowSize from '../hooks/useWindowSize';
//import useResizeObserver from "./useResizeObserver";

/**
 * Component that renders a FreeHand
 */
import { View } from 'react-native';

function FreeHand({ width, height, data, id = "myFreeHandData" }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const size = useWindowSize();
  //const dimensions = useResizeObserver(wrapperRef);
const MouseDraw=({ x, y, width, height, thickness})=>{
	const [drawing, setDrawing] = useState(false);
	const [currentLine, setCurrentLine] = useState({thickness, points:[]});
	const [lines, setLines] = useState([]);
	function enableDrawing(){
		setCurrentLine({thickness, points:[]});
		setDrawing(true);

	}
	function disableDrawing(){
		setDrawing(false);
		setLines((lines)=>[...lines, currentLine]);

	}
	
	
	const Line = ({ thickness, points }) => {
		const line = useMemo(() =>{
			return d3.line()
			.x((d) => d.x)
			.y((d) => d.y)
			.curve(d3.curveBasisOpen);
			
		},[]);
		return(
		<path d={line(points)}
		style={{
			stroke:"black",
			strokeWidth: thickness,
			strokeLinejoin: "round",
			strokeLinecap: "round",
			fill: "none"
		}}
		/>
		);
		
	};
	
	
	const mouseMove = useCallback(
	function (event){
		  const t = d3.pointers(event, this);
		  const [x,y] = [d3.mean(t, d => d[0]), d3.mean(t, d => d[1])];
		//const [x,y] = d3.pointers(event);
		if(drawing){
			setCurrentLine((line)=>({
				...line,
				points:[...line.points, { x, y }]
			}));
		}
	},[drawing]);
	useEffect(() => {
		const area = d3.select(svgRef.current);
		area.on("mousemove touchmove", mouseMove);
		return () => area.on("mousemove touchmove", null);
	},[mouseMove]);
	
	return(
		<g
		transform={`translate(${x}, ${y})`}
		ref={svgRef}
		onMouseDown={enableDrawing}
		onMouseUp={disableDrawing}
		onTouchStart={enableDrawing}
		onTouchEnd={disableDrawing}>
		<rect x={0} y={0} width={width} height={height} style={{fill:"pink"}} />
			{lines.map((line,i) => (
			<Line thickness={line.thickness} points={line.points} key={i} />))}
		<Line thickness={currentLine.thickness} points={currentLine.points} />
		</g>
	);
}

  return (
    <React.Fragment>
      <View ref={wrapperRef} style={{ marginBottom: "2rem" }}>
        <svg ref={svgRef} width={width} height={height} style={{border: "1px solid red"}}>
          <MouseDraw x={0} y={0} width={width} height={height} thickness={data[0]}/>
        </svg>
      </View>
    </React.Fragment>
  );
}

export default FreeHand;