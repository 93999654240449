import '../_mockLocation';
import React, { useContext, useCallback } from 'react';
import { StyleSheet, SafeAreaView, Platform } from 'react-native';
import { Text } from 'react-native-elements';
//import { withNavigationFocus } from '@react-navigation/native';
import Map from '../components/Map';
import { Context as LocationContext } from '../context/LocationContext';
import useLocation from '../hooks/useLocation';
import TrackForm from '../components/TrackForm';
import { FontAwesome } from '@expo/vector-icons';
/* if (Platform.OS !== 'web') {
  Map = require('../components/Map').default;
} */
const TrackCreateScreen = ({ isFocused }) => {
  const {
    state: { recording },
    addLocation
  } = useContext(LocationContext);
  const callback = useCallback(
    location => {
      addLocation(location, recording);
    },
    [recording]
  );
  const [err] = useLocation(isFocused || recording, callback);

  return (
    <SafeAreaView forceInset={{ top: 'always' }} style={styles.container}>
      <Text h2>Create a Track</Text>
      {Platform.OS !== 'web' && <Map />}
      {err ? <Text>Please enable location services</Text> : null}
      <TrackForm />
    </SafeAreaView>
  );
};

TrackCreateScreen.navigationOptions = {
  title: 'Add Track',
  tabBarIcon: <FontAwesome name="plus" size={20} />
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    marginBottom: 250
  }
});

export default TrackCreateScreen;
