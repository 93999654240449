import React, { useRef, useEffect, useState } from "react";
import * as d3 from 'd3';
import ReactDOM from "react-dom";
import useWindowSize from '../hooks/useWindowSize';
import trackerApi from '../api/tracker';
//import useResizeObserver from "./useResizeObserver";

/**
 * Component that renders a ZoomableLineChart
 */
import { View } from 'react-native';

function makeDraggable(comp: React.Component) {
  let translateX = 0;
  let translateY = 0;
  const handleDrag = d3.drag()
  .subject(function() {
    const me = d3.select(this);
    return { x: translateX, y: translateY }
  })
  .on('drag', function(event) {
    const me = d3.select(this);
    const transform = `translate(${event.x}, ${event.y})`;
    translateX = event.x;
    translateY = event.y;
    me.attr('transform', transform);
  });
  const node = ReactDOM.findDOMNode(comp);
  handleDrag(d3.select(node));
}
function ZoomableLineChart({ width, height, tagdata, id = "myZoomableLineChart" }) {
	
  const svgRef = useRef();
  const wrapperRef = useRef();
  const size = useWindowSize();
  //const dimensions = useResizeObserver(wrapperRef);
  const [currentZoomState, setCurrentZoomState] = useState();
  
    const [data, setData] = useState({tag:"Light%",samples:[{time:1611055800, val:4}]});

  useEffect(() => {
    async function fetchMyAPI() {
	
	var tagdetail="u4plc2-LC8_P_II_4301MD1.PV-AI"
	var totime = new Date().getTime()/1000
	totime=parseInt(totime)
	var ftime= totime - 86400
	console.log("fetching from link ", "https://infothrill.in/dataFile/"+tagdetail+"/"+ftime+"/"+totime);
      let response = await trackerApi.get('/dataFile/'+tagdetail+'/'+ftime+'/'+totime);
	  console.log("response1", response)
      // response = await response.json()
	  console.log("tagdata", tagdata)
      setData(response.data);
    }

    fetchMyAPI()
  }, [])

  // will be called initially and on every data change
  useEffect(() => {
    const svg = d3.select(svgRef.current)
    .attr("width", width)
    .attr("height", height)
    .style("border", "1px solid red")
	.style("overflow", "visible")
	.style("background-color", "white");

	makeDraggable(svgRef.current);
    const svgContent = svg.select(".content");
    //const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();
var title=tagdata;
	// d3.json("https://infothrill.in/dataFile/"+tagdetail+"/"+ftime+"/"+totime)
  // .then((da) => {

    // do something with the data
	//data=da;
  console.log("data",data);
//data={tag:"Light%",samples:[{time:1611055800, val:4},{time:1611063000, val:67},{time:1611070200, val:89}]}
    // scales + line generator
	// parse the date / time
var parseTime = d3.timeParse("%s");
  // format the data
  data.samples.forEach(function(d) {
      d.time = parseTime(d.time);
      d.val = +d.val;
  });
    const xScale = d3.scaleTime()
      .domain(d3.extent(data.samples, function(d) { return d.time; }))
      .range([30, width - 15]);

    if (currentZoomState) {
      const newXScale = currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }
svg.select(".heading")
.attr("transform", "translate("+(width/2)+"," + (15) + ")")
.attr('text-anchor','middle')
.text(title);
svg.select(".details")
.attr("transform", "translate("+(width/2)+"," + (30) + ")")
.attr('text-anchor','middle')
.text("Avg:"+d3.mean(data.samples, d => d.val).toFixed(2));

    const yScale = d3.scaleLinear()
      .domain([d3.max(data.samples, d => d.val)+30, 0])
      .range([0, height - 45]);

    const lineGenerator = d3.line()
      .curve(d3.curveStepAfter)
	  .defined(d => !isNaN(d.val))
	  .x((d, index) => xScale(d.time))
      .y((d, index) => yScale(d.val));

    // render the line
    svgContent
      .selectAll(".myLine")
      .data([data.samples])
      .join("path")
      .attr("class", "myLine")
      .attr("stroke", "black")
      .attr("fill", "none")
      .attr("d", lineGenerator);

	  
/* 	svgContent
	.selectAll(".myRect")
      .data(data.samples)
	  .join("rect")
	  .attr("class", "myRect")
            .attr("x", (d, i) => xScale(d.time))
            .attr("y", (d) => yScale(d.val))
            .attr("width", 40)
            .attr("height", (d) => height-30-yScale(d.val))
            .attr("fill", "orange");
	  
	  
	  
    svgContent
      .selectAll(".myDot")
      .data(data.samples)
      .join("circle")
      .attr("class", "myDot")
      .attr("stroke", "black")
      .attr("r", 4)
      .attr("fill", "orange")
      .attr("cx", (value, index) => xScale(value.time))
      .attr("cy", (value, index) => yScale(value.val));

	  svgContent
      .selectAll(".myDotName")
      .data(data.samples)
      .join("text")
      .attr("class", "myDotName")
      .attr("stroke", "black")
      .attr("fill", "black")
	  .attr("text-anchor",'middle')
      .attr("x", (value, index) =>  xScale(value.time))
      .attr("y", (value, index) => yScale(value.val)-25)
	  .text(value =>value.val); */
	  
    // axes
    const xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat("%I:%M %p"));
	
svg.select(".x-axis")
.attr("transform", "translate("+(0)+"," + (height-30) + ")")
.call(xAxis);
	


    const yAxis = d3.axisLeft(yScale);
    svg.select(".y-axis").attr("transform", "translate("+(30)+"," + (0) + ")").call(yAxis);

    // zoom
    const zoomBehavior = d3.zoom()
      .scaleExtent([1, 5])
      .translateExtent([
        [0, 0],
        [width, height]
      ])
      .on("zoom", () => {
        const zoomState = d3.zoomTransform(svg.node());
        setCurrentZoomState(zoomState);
      });

    svg.call(zoomBehavior);
	
	//tooltip functions start
	const bisect = (mx)=>{
		console.log(mx);
  const bisect = d3.bisector(d => d.time).left;

    const date = xScale.invert(mx);
    const index = bisect(data.samples, date, 1);
    const a = data.samples[index - 1];
    const b = data.samples[index];

    return b && (date - a.time > b.time - date) ? b : a;

}

	const callout = (g, value) => {
  if (!value) return g.style("display", "none");

  g
      .style("display", null)
      .style("pointer-events", "none")
      .style("font", "10px sans-serif");

  const path = g.selectAll("path")
    .data([null])
    .join("path")
      .attr("fill", "white")
      .attr("stroke", "black");

  const text = g.selectAll("text")
    .data([null])
    .join("text")
    .call(text => text
      .selectAll("tspan")
      .data((value + "").split(/\n/))
      .join("tspan")
        .attr("x", 0)
        .attr("y", (d, i) => `${i * 1.1}em`)
        .style("font-weight", (_, i) => i ? null : "bold")
        .text(d => d));

  const {x, y, width: w, height: h} = text.node().getBBox();

  text.attr("transform", `translate(${-w / 2},${15 - y})`);
  path.attr("d", `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
}
	
	
	  const tooltip = svg.append("g");

  svg.on("touchmove mousemove", function(event) {
	  console.log("--",bisect(d3.pointer(event, this)[0]));
    const {time, val} = bisect(d3.pointer(event, this)[0]);

    tooltip
        .attr("transform", `translate(${xScale(time)},${yScale(val)})`)
        .call(callout, `${val}
${time}`);
  });

  svg.on("touchend mouseleave", () => tooltip.call(callout, null));
	
	//tooltip functions end
	// })
  // .catch((error) => {
	  // console.log(error);
    // console.error("Error loading the data");
  // });
	
	
  }, [currentZoomState, data, size]);

  return (
    <React.Fragment>
      <View ref={wrapperRef} style={{ marginBottom: "2rem" }}>
        <svg ref={svgRef}>
          <defs>
            <clipPath id={id}>
              <rect x="0" y="0" width="100%" height="100%" />
            </clipPath>
          </defs>
		   <text className="heading" />
		   <text className="details" />
          <g className="content" clipPath={`url(#${id})`}></g>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </View>
    </React.Fragment>
  );
}

export default ZoomableLineChart;