import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, FlatList, TouchableOpacity, View, ScrollView } from 'react-native';
import {TextInput, Button} from 'react-native-paper';
import Colors from '../constants/Colors'
import { useFocusEffect } from '@react-navigation/native';
import { ListItem } from 'react-native-elements';
import { Context as TrackContext } from '../context/TrackContext';
import { Context as AuthContext } from '../context/AuthContext';
import {navigate} from '../navigationRef';
import BarChart from '../d3Items/BarChart';
import FreeHand from '../d3Items/FreeHand';
import ZoomableLineChart from '../d3Items/ZoomableLineChart';
import D3Table from '../d3Items/D3Table';
import DragData from '../d3Items/DragData';
import DragAndDrop from 'react-native-dragging-list'
import useWindowSize from '../hooks/useWindowSize';
//import "./styles.css";
const datas = [
  [10, 30, 40, 20, 99],
  [10, 40, 30, 20, 50, 10],
  [60, 30, 40, 20, 30]
]
var i = 0;



const DraggableListScreen = ({ navigation }) => {
  const { state, fetchTracks } = useContext(TrackContext);

  useFocusEffect(
    React.useCallback(() => {
      //alert('Screen was focused');
      //fetchTracks();
      // Do something when the screen is focused
      return () => {
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );
  function RenderItem({ item }) {
    return (
      <View>
        <Text style = {{textAlign: 'center'}}> { item } </Text>
      </View>
    )
  }
  
  function DraggingHandle() {
      return (
        <View>
          <Text>....</Text>
        </View>
      )
    }
  
  const listItemStyling = { 
    width: '100%', 
    flexDirection: 'row', 
    justifyContent: 'space-around', 
    alignItems: 'center', 
    height: 70, 
    borderWidth: 1, 
    borderColor: 'black', 
    backgroundColor: 'grey' 
  }

  //const data = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]

  const size = useWindowSize();
  const [data, setData] = useState([]);
const [draggedData, setDragData] = useState(null);
    useEffect(() => {
        changeData();
    }, []);

    const changeData = () => {
        setData(datas[i++]);
        if(i === datas.length) i = 0;
    }
  return (
    <>
	<ScrollView>
       <View style = {{ flex: 1}}>

       <Button labelStyle={{alignItems:'center'}} mode="contained" color={Colors.primary} onPress = {() => navigate('GfxFileList')}>
      Go to GfxFiles</Button>
        </View> 

			<View style={{ justifyContent:"center", alignItems:"center" }}>
            <Button onClick={changeData}>Change Data</Button>
			<FreeHand width={600} height={size.window.height/4} data={data} />
            <ZoomableLineChart width={600} height={size.window.height/4} data={data} />
			<D3Table width={600} height={size.window.height/4} data={data} />
			<DragData width={600} height={size.window.height/4} data={data} />
			</View>
        </ScrollView>
    </>
  );
};

DraggableListScreen.navigationOptions = {
  title: 'DraggableList'
};

const styles = StyleSheet.create({});

export default DraggableListScreen;
