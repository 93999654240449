import { AsyncStorage } from 'react-native';
import createDataContext from './createDataContext';
import trackerApi from '../api/tracker';
//import { RootNavigation.navigate } from '../navigationRef';
import * as RootNavigation from '../navigationRef';
const authReducer = (state, action) => {
  switch (action.type) {
    case 'add_error':
      return { ...state, errorMessage: action.payload };
    case 'signin':
      return { errorMessage: '', token: action.payload, userEmail: action.userEmail };
    case 'clear_error_message':
      return { ...state, errorMessage: '' };
    case 'signout':
      return { token: null, errorMessage: '' };
    default:
      return state;
  }
};

const tryLocalSignin = dispatch => async () => {
  const token = await AsyncStorage.getItem('token');
  const email = await AsyncStorage.getItem('userEmail');
  if (token) {
    dispatch({ type: 'signin', payload: token, userEmail: email });
    //RootNavigation.navigate('TrackList');
  } else {
    console.log("failed to go to signup");
    //RootNavigation.navigate('loginflow',{Screen:'Signin'});
  }
};

const clearErrorMessage = dispatch => () => {
  dispatch({ type: 'clear_error_message' });
};

const signup = dispatch => async ({ email, password }) => {
  try {
    const response = await trackerApi.post('/signup', { email, password });
    await AsyncStorage.setItem('token', response.data.token);
    await AsyncStorage.setItem('userEmail', email);
    dispatch({ type: 'signin', payload: response.data.token, userEmail: email });

    RootNavigation.navigate('Signin');
  } catch (err) {
    dispatch({
      type: 'add_error',
      payload: 'Something went wrong with sign up'
    });
  }
};

const signin = dispatch => async ({ email, password }) => {
  try {
    const response = await trackerApi.post('/signin', { email, password });
    await AsyncStorage.setItem('token', response.data.token);
    await AsyncStorage.setItem('userEmail', email);
    console.log("SigninResponse:",response.data);
    dispatch({ type: 'signin', payload: response.data.token, userEmail:email });
    RootNavigation.navigate('mainFlow',{screen:'TrackList'});
  } catch (err) {
    console.log("signinError:",err.response.data.error);
    dispatch({
      type: 'add_error',
      payload: err.response.data.error
    });
  }
};

const signout = dispatch => async () => {
  await AsyncStorage.removeItem('token');
  await AsyncStorage.removeItem('userEmail');
  dispatch({ type: 'signout' });
  RootNavigation.navigate('loginFlow',{screen:'Signin'});
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { signin, signout, signup, clearErrorMessage, tryLocalSignin },
  { token: null, errorMessage: '', userEmail:'Rajat' }
);
