import React, { useRef, useContext, useState, useEffect  } from "react";
import ReactDOM from "react-dom";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import * as d3 from 'd3';
import useWindowSize from '../../hooks/useWindowSize';
import Svg, {
  Text, Rect, G
} from 'react-native-svg';

function makeDraggable(comp: React.Component) {
  let translateX = 0;
  let translateY = 0;
  const handleDrag = d3.drag()
  .subject(function() {
    const me = d3.select(this);
    return { x: translateX, y: translateY }
  })
  .on('drag', function(event) {
	  console.log("dragEvent",event);
    const me = d3.select(this);
    const transform = `translate(${event.x}, ${event.y})`;
    translateX = event.x;
    translateY = event.y;
    me.attr('transform', transform);
  });
  const node = ReactDOM.findDOMNode(comp);
  handleDrag(d3.select(node));
}



function makeZoomable(comp1: React.Component, comp2: React.Component, windowWidth, windowHeight, width, height) {
	const node1 = ReactDOM.findDOMNode(comp1);
	const node2 = ReactDOM.findDOMNode(comp2);
	  // Define a "minzoom" whereby the "Countries" is as small possible without leaving white space at top/bottom or sides
  var minZoom = Math.max(windowWidth / width, windowHeight / height);
  // set max zoom to a suitable factor of this value
  var maxZoom = 20 * minZoom;

  // define X and Y offset for centre of map to be shown in centre of holder
  var midX = (windowWidth - minZoom * width) / 2;
  var midY = (windowHeight - minZoom * height) / 2;

  const handleZoom = d3.zoom()
        .scaleExtent([1, maxZoom])
		.translateExtent([[0, 0], [width, height]])
  .on('zoom', function(event) {
	  console.log(event.transform);

    const me = d3.select(this);
	
	 var t = event.transform;

    //svg.attr("transform", t);
	 me.attr('transform', t);
    //me.attr('transform', event.transform);
  })
  d3.select(node1).call(handleZoom.transform, d3.zoomIdentity.translate(0, 0).scale(1));
  
  handleZoom(d3.select(node2));
}


const MainBackComp = (props) => {
	const myRef = useRef();
	const myRef2 = useRef();
const size= useWindowSize();

  
  useEffect(() => {
    if (myRef.current) {
      //console.log(myRef.current);
	  if(props.config.children[0]._attr.displayType!=="replace"){
	  makeDraggable(myRef.current);
	  }else{
	  makeZoomable(myRef.current, myRef2.current, size.window.width, size.window.height, props.config.children[0]._attr.width, props.config.children[0]._attr.height);
	  }
    }
  }, [myRef])

  
    return (

        <Svg ref={myRef} height={props.config.children[0]._attr.displayType==="replace"?size.window.height:props.config.children[0]._attr.height>size.window.height-50?size.window.height-50:props.config.children[0]._attr.height} width={props.config.children[0]._attr.displayType==="replace"?size.window.width:props.config.children[0]._attr.width>size.window.width-50?size.window.width-50:props.config.children[0]._attr.width} viewBox={`0 0 ${props.config.children[0]._attr.width} ${props.config.children[0]._attr.height}`} preserveAspectRatio="xMidYMid meet">
        <G ref={myRef2}>
		<Rect
              x="0"
              y="0"
              width={props.config.children[0]._attr.width}
              height={props.config.children[0]._attr.height}
              stroke="black"
              strokeWidth="2"
              fill={props.config.children[0]._attr.backColor}
            />
        {props.children}
		</G>
      
        </Svg>
    );
  };
  
  export default MainBackComp;
