import React from "react";
import {View} from 'react-native';
import Colors from '../../constants/Colors';
import useWindowSize from '../../hooks/useWindowSize';
import Svg, {Text,
 ForeignObject
} from 'react-native-svg';
import TouchableOpacityG from '../TouchableOpacityG';
import { Popup } from 'semantic-ui-react';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import evalExp from './EvalExp';
import {evalColor, evalVisibility, evalFontSize, roundN} from './AttribIndex';
import ZoomableLineChart from '../../d3Items/ZoomableLineChart';
/* export default props => (
  
  <View style={{position: 'absolute', top: h(props.config._attr.top), left: w(props.config._attr.left), width:w(props.config._attr.width), height:h(props.config._attr.height)}}>
     <Button labelStyle={{alignItems:'center'}} mode="contained" color={props.config.children[1]._attr.backColor}>{props.config.children[1].children[0]._attr.caption}</Button>
  </View>
); */
const data =[10, 30, 40, 20, 99]
const NumericDisplayComp = (props) => {
  const x=parseInt(props.config._attr.left) && parseInt(props.config._attr.width) ? parseInt(parseInt(props.config._attr.left) + parseInt(props.config._attr.width/2)) : null;
  const y=parseInt(props.config._attr.height) && parseInt(props.config._attr.top) ? parseInt(parseInt(props.config._attr.top) + parseInt(props.config._attr.height/2)) : null;
  
  //console.log("NumericDisplayProp",props);
  const size= useWindowSize();
  const style = {
    borderRadius: 4,
    fontSize:10,
    fontWeight: 'bold',
    color:'black',
    backgroundColor:'white',
  }
  
  return (
    <>
        <Popup
		on='click'
     trigger={
     <Svg>
     <TouchableOpacityG onPress={()=>{console.log(`${props.config.children[props.config.children.map((e)=>e.name).indexOf('connections')].children[0]._attr.expression}=someValue}`);}}>
       <Svg>
	   <Text
	  fill={evalColor(props)!==-1?evalColor(props).foreColor1:props.config._attr.foreColor}
	  stroke={evalColor(props)!==-1?evalColor(props).foreColor1:props.config._attr.foreColor}
    fontSize={evalFontSize(props)}
	fontFamily={props.config._attr.fontFamily}
    fontWeight="normal"
    x={parseInt(props.config._attr.left)}
    y={parseInt(props.config._attr.top)}
    textAnchor="start"
	dominantBaseline="hanging"
  >
    {roundN(evalExp(props.config.children[props.config.children.map((e)=>e.name).indexOf('connections')].children[0]._attr.expression),props.config._attr.decimalPlaces)}
	  </Text>
		
      </Svg>
       </TouchableOpacityG>
       {props.children}
       </Svg>
       }
       position={props.config._attr.left<size.window.width/2?"top right":"top left"}
       content={
		  <ZoomableLineChart width={600} height={400} tagdata={`${props.config.children[props.config.children.map((e)=>e.name).indexOf('connections')].children[0]._attr.expression}`} /> }
       size='tiny'

      />

    </>
  );
};

export default NumericDisplayComp;

