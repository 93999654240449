import React, { useContext, useEffect } from 'react';
import { StyleSheet, Text, FlatList, TouchableOpacity, View } from 'react-native';
import {TextInput, Button} from 'react-native-paper';
import Colors from '../constants/Colors'
import { useFocusEffect } from '@react-navigation/native';
import { ListItem } from 'react-native-elements';
import { Context as TrackContext } from '../context/TrackContext';
import { Context as AuthContext } from '../context/AuthContext';
import {navigate} from '../navigationRef';
const TrackListScreen = ({ navigation }) => {
  const { state, fetchTracks } = useContext(TrackContext);

  useFocusEffect(
    React.useCallback(() => {
      //alert('Screen was focused');
      fetchTracks();
      // Do something when the screen is focused
      return () => {
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );



  return (
    <>
      {/* <NavigationEvents onWillFocus={fetchTracks} /> */}
      <View>
      <FlatList
        data={state}
        keyExtractor={item => item._id}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate('TrackDetail', { _id: item._id })
              }
            >
              <ListItem chevron title={item.name} />
            </TouchableOpacity>
          );
        }}
      />
       <Button labelStyle={{alignItems:'center'}} mode="contained" color={Colors.primary} onPress = {() => navigate('GfxFileList')}>
      Go to GfxFiles</Button>
        </View>
    </>
  );
};

TrackListScreen.navigationOptions = {
  title: 'TracksList'
};

const styles = StyleSheet.create({});

export default TrackListScreen;
