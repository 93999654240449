import React, { useRef, useContext, useState, useEffect  } from "react";
import { View, StyleSheet, Text, SafeAreaView } from 'react-native';
import {TextInput, Button} from 'react-native-paper';
import Colors from '../constants/Colors'
import { useFocusEffect } from '@react-navigation/native';
import trackerApi from '../api/tracker';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import Spacer from '../components/Spacer';
//import { Context as AuthContext } from '../context/AuthContext';
import { Context as GfxContext } from '../context/GfxContext';
import {Float32ToHex, HexToFloat32, convertNumArrayToHexString} from "../components/GfxComponents/float32encoding.js";
//import io from 'socket.io-client';
import { navigate } from '../navigationRef';
//let socket=io();
let k="";
let stAddress=0;
let stAddressW=-1;
const AccountScreen = () => {
const { state, fetchFiles, addFileTags, deleteFileTags, deleteFile, activateFile, setFileTags, clearFiles, addDBTags, updateTime, addLogic } = useContext(GfxContext);
  const count = useSocketSelector(state => state.count);
  const str=useSocketSelector(st => st.FromAPI);
  const [value, onChangeText] = useState("");
 const sendToAPI=useEmitEvent('ToAPI');
 
 
 function hex_to_ascii(str1)
 {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		if(parseInt(hex.substr(n, 2), 16)==0){
			break;
		}
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
 }
 function ascii_to_hexa(str)
  {
	var arr1 = [];
	for (var n = 0, l = str.length; n < l; n ++) 
     {
		var hex = Number(str.charCodeAt(n)).toString(16);
		arr1.push(hex);
	 }
	return arr1.join('');
   }
 
 
const sendLogicCmd=(tlist)=>{
tlist.logic.map(deviceID =>{

var msg=[];
msg.push(1); //0
msg.push(8); //1 // read Logic
msg.push(stAddress); //2
msg.push(10); //3
console.log("msg=",convertNumArrayToHexString(msg));
sendToAPI(convertNumArrayToHexString(msg));
});
}
  useFocusEffect(
    React.useCallback(() => {
      /* socket= io('https://infothrill.in?token='+state.token);
          socket.on('connect', () => {
            console.log("connected Hurray");
            setIsConnected(true);
          });
          // // on reconnection, reset the transports option, as the Websocket
          // // connection may have failed (caused by proxy, firewall, browser, ...)
          // socket.on('reconnect_attempt', () => {
          //   socket.io.opts.transports = ['polling', 'websocket'];
          // });
    
        socket.on("FromAPI", data => {
          console.log("FromAPI:",data);
          //let d=data.split('#').pop().split(';')[0];
            setResponse((response+data).replace(";", "\n"));
          //console.log("extracted data:",d);
        });
        socket.on('unauthorized', (error) => {
          console.log("unauth error yayyyy");
          if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
            // redirect user to login page perhaps or execute callback:
            navigate('loginFlow',{screen:'Signin'});
            //callback();
            console.log('User token has expired');
          }
        }); */
		clearInterval(timer);
        	var timer = setInterval(() => {
		var da = new Date();
		updateTime(da.getTime());
	//if(state.filetags.length>0){sendCntrTags(state);}
		}, 2000);
      //alert('Screen was focused');
      // Do something when the screen is focused
      return () => {
        /* socket.disconnect();
        console.log("Socket disconnected"); */
        //navigate('TrackList');
		clearInterval(timer);
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );

/* const sendToAPI=()=>{
  console.log("ToAPI: ", value);
  //const socket = io('https://infothrill.in?token='+state.token);
  socket.emit("ToAPI", value);
} */
 // useEffect(() => {
    // if(state.logic.length>0){sendLogicCmd(state);}
  // }, [state.LastRecTime]); // Only re-run the effect if count changes
  
 useEffect(() => {
    if(state.logic.length>0){sendLogicCmd(state);}
  }, [stAddress]); // Only re-run the effect if count changes 
  
  const sendLogicWriteCmd=(tlist)=>{
var hexlogic=ascii_to_hexa(tlist.logic[tlist.logic.map(function(e) { return e.deviceID; }).indexOf(1)].logic);

	  //console.log("hexlogic",hexlogic);
var msg2=hexlogic.substring(stAddressW*2,stAddressW*2+20).toUpperCase()+'00'
if(msg2.length>2){
var msg=[];
msg.push(1); //0
msg.push(10); //1 // Write Logic
msg.push(stAddressW); //2
msg.push(msg2.length/2); //3
//console.log("msg=",convertNumArrayToHexString(msg));
var msg1=convertNumArrayToHexString(msg);
msg1=msg1+msg2;
console.log("msg1",msg1);
sendToAPI(msg1);
}else{
var msg=[];
msg.push(1); //0
msg.push(2); //1 // set digital
msg.push(0); //2 // adrress 0 for restatrting logic reading/Execution
msg.push(1); //3
msg.push(0);//4 //data to write zero at zero location for restatrting logic reading/Execution
console.log("msg",convertNumArrayToHexString(msg));
sendToAPI(convertNumArrayToHexString(msg));
}
}
  
   useEffect(() => {
    if(state.logic.length>0 && stAddressW>=0){console.log("from stAddressW=",stAddressW);sendLogicWriteCmd(state);}
  }, [stAddressW]); // Only re-run the effect if count changes 
  

  
const SenderButton = (sendvalue) => {

  //const sendToAPI=useEmitEvent('ToAPI');
  const sendLogic=(v)=>{
	    var ob={deviceID:1,logic:v};
		addLogic(ob);
		console.log("from sendLogic button");
		stAddressW=0;
		//sendLogicWriteCmd(ob);
  }

  return (
    <>
      <Button labelStyle={{alignItems:'center'}} mode="contained" color={Colors.primary} onClick={() => sendLogic(sendvalue.sendvalue)}>Send</Button>
    </>
  )
}

   useEffect(() => {
	  console.log("str",str);
   	var list=[];
var stA=0;
var func=1;
var dID=0;
  var datalen=0;
		for (var i = 0; i < str.length; i += 2) {
    list.push(str.substring(i, i + 2));
}
		dID=parseInt(list[0],16);
		func=parseInt(list[1],16);
		datalen=parseInt(list[4], 16);
		stA=parseInt(list[2] + list[3], 16);
	if(func==9){//logic read
		var rec=hex_to_ascii(str.substring(10));
		if(rec.length>0){
		k=k+rec;
		stAddress=stAddress+10;
		//console.log("logic=",rec);
		}
		else if(k.length>5){
			var ob={deviceID:dID,logic:k};
		addLogic(ob);
		}
	}
	if(func==11){//logic written
		var recW=hex_to_ascii(str.substring(10));
		if(recW.length>0){
		k=k+recW;
		stAddressW=stAddressW+10;
		//console.log("logic=",rec);
		}
		else if(k.length>5){
			//var ob={deviceID:dID,logic:k};
		//addLogic(ob);
		}
	}
  }, [str]); // Only re-run the effect if count changes
  
const MsgDisplay = () => {
  return (

	<p>{k}</p>
  );
}
  return (
    // <SafeAreaView forceInset={{ top: 'always' }}>
    <SafeAreaView style={styles.container}>
      <Text style={{ fontSize: 48 }}>LogicScreen</Text>
      <MsgDisplay />
      <Spacer>
    <View style={styles.sender}>
      <TextInput
      label='Type Message Here'
      onChangeText={text => onChangeText(text)}
      value={value}
    />
    <SenderButton sendvalue={value} />
    </View>
      </Spacer>
      <Spacer>
        <Button labelStyle={{alignItems:'center'}} mode="contained" color={Colors.primary} onPress = {() => navigate('mainFlow',{screen:'PanResponder'})}>
        Go to Pan Responder</Button>
      </Spacer>
    </SafeAreaView>
    
  );

};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems:'center',
    margin: 50
  },
  sender: {
    flex: 1,
    flexDirection:'row',
    justifyContent:'space-around'
  }
});

export default AccountScreen;
