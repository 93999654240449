import React, { useRef, useContext, useState, useEffect  } from "react";
import { useFocusEffect } from '@react-navigation/native';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import { Animated, View, StyleSheet, PanResponder, Text, TouchableOpacity } from "react-native";
import useWindowSize from '../hooks/useWindowSize';
import RenderCard from "../components/RenderCard.js";
import {Float32ToHex, HexToFloat32, convertNumArrayToHexString} from "../components/GfxComponents/float32encoding.js";
//import sendCntrTags from "../components/GfxComponents/sendCntrTags.js";
import { Context as GfxContext } from '../context/GfxContext';
import Svg, {Rect} from 'react-native-svg';
import {navigate} from '../navigationRef';
//import { ReactNativeZoomableView } from '@dudigital/react-native-zoomable-view';

  const PanResponderScreen = ({ route, navigation }) => {
  const { state, fetchFiles, addFileTags, deleteFileTags, deleteFile, activateFile, setFileTags, clearFiles, addDBTags, updateTime } = useContext(GfxContext);
  const count = useSocketSelector(state => state.count);
  const str=useSocketSelector(st => st.FromAPI);
  function isHex(h) {
  //var re = /[0-9A-Fa-f]{6}/g;
  var re = /^[0-9a-fA-F]+$/;
return (re.test(h))
}
function hex_to_ascii(str1)
 {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
 }


  const { filename } = route.params;
  const actualFileName=filename.split("/")[filename.split("/").length - 1];
  var tags=[];
  function logger(config) {
    if (typeof config._attr !== "undefined") {
    if (typeof config._attr.expression !== "undefined") {
      var sPos=0;
      var exp=config._attr.expression;

      while(sPos!==-1){
        var tag=exp.substring(exp.indexOf("{",sPos)+1,exp.indexOf("}",sPos));
      if(tags.map(function(e) { return e; }).indexOf(tag) < 0){
        tags.push(tag);
        }
        
    //console.log("sPos=",sPos);
    //console.log("tag=",tag);
    sPos=exp.indexOf('{', sPos+1);
  }
    }
	if (typeof config._attr.tag !== "undefined") {
      var sPos=0;
      var exp=config._attr.tag;

      while(sPos!==-1){
        var tag=exp.substring(exp.indexOf("{",sPos)+1,exp.indexOf("}",sPos));
      if(tags.map(function(e) { return e; }).indexOf(tag) < 0){
        tags.push(tag);
        }
        
    //console.log("sPos=",sPos);
    //console.log("tag=",tag);
    sPos=exp.indexOf('{', sPos+1);
  }
    }
  }
      (typeof config.children !== "undefined")
            && config.children.map(c => logger(c))
  }

  const sendTagList=useEmitEvent('TagList');
	const sendToAPI=useEmitEvent('ToAPI');
	
const sendCntrTags=(tlist)=>{
	
if(typeof tlist.filetags!=="undefined"){
tlist.tagDB.map(tagDBtag =>{tlist.filetags[tlist.filetags.map(function(e) { return e.filename; }).indexOf(tlist.activeFileName)].tags.map(tlistTag => {if(tlistTag===tagDBtag.Tag){
	
	  var d = new Date();
	  if((d.getTime()-tagDBtag.sent)>60000){
		var ob={Tag:tagDBtag.Tag,type:tagDBtag.type,Address:tagDBtag.Address,value:tagDBtag.value,sent:d.getTime()};
		addDBTags(ob);
	  }
  if((d.getTime()-tagDBtag.sent)>2000 && (d.getTime()-tagDBtag.sent)<8000){
	  console.log("sending for tag=",tagDBtag.Tag);
  //console.log(`Time Elapsed=${d.getTime()-tagDBtag.sent}, TimeNow=${d.getTime()}, PrevTime=${tagDBtag.sent}`);
	var msg=[];
msg.push(1); //0
if(tagDBtag.type!=="B"){
msg.push(4); //1 // read Analog
}else{
msg.push(0); //1 // read digital
}
msg.push(tagDBtag.Address); //2
if(tagDBtag.type==="R" || tagDBtag.type==="L"){
msg.push(4); //3
}
if(tagDBtag.type==="I"){
msg.push(2); //3
}
if(tagDBtag.type==="B"){
msg.push(1); //3
}
msg.push(tagDBtag.value);//4 onwards
console.log("msg=",convertNumArrayToHexString(msg));
sendToAPI(convertNumArrayToHexString(msg));
  }
}})});

}
}
	
    const sendTags=(tlist)=>{
		sendCntrTags(tlist);
      sendTagList(tlist.filetags);
    }

  useFocusEffect(
    React.useCallback(() => {
      //alert('Screen was focused');
	  //console.log("PanResponder Screen was focused");
      // console.log("filename req:",filename);
	  
	  var dat = new Date();
		updateTime(dat.getTime());
	  // var fil=state.activeFileName;
       activateFile(actualFileName);
	  // deleteFileTags(fil); 
      //console.log("navigation details:", navigation);
      fetchFiles(actualFileName);
      navigation.setOptions({title:state.activeFileName.split(".")[0]});
      //fetchFiles();
      //console.log(count);
	//timer = setInterval(myFn, 4000);
    clearInterval(timer);
	var timer = setInterval(() => {
		var da = new Date();
		updateTime(da.getTime());
	//if(state.filetags.length>0){sendCntrTags(state);}
		}, 2000);
	  
      // Do something when the screen is focused
      return () => {
		  //console.log("PanResponder Screen was Removed");
        /* console.log("route details:", route);
        //navigation.setParams({filename:""});
        //{state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)].data.map(config => logger(config))}
        console.log("fileToRemove",state.activeFileName);
        console.log("fileList=", state.files);
        console.log("fileIndexToRemove",state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName));
    if(state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1){  */
      //deleteFileTags(state.activeFileName); 
	
        //alert('Screen was unfocused');
		clearInterval(timer);
        clearFiles();
		sendTags("{}");
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );
  useEffect(() => {
	  //console.log(`PanResponder Screen: setFileTags of file ${state.activeFileName} Started`);
	  if(state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)===-1 && state.activeFileName!==""){
		  fetchFiles(state.activeFileName);
	  }
	  navigation.setOptions({title:state.activeFileName.split(".")[0]});
    {state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)].data.map(config => logger(config))}
    //console.log("fileIndex",state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName));
    if(state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1){ setFileTags(state.activeFileName, tags);}
  }, [state.activeFileName, state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)]); // Only re-run the effect if count changes
 
  useEffect(() => {
	  //console.log(`PanResponder Screen: sending of FileTags of file ${state.activeFileName} Started`);
	  navigation.setOptions({title:state.activeFileName.split(".")[0]});
    //fetchFiles(state.activeFileName);
    //{state.filetags.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)].data.map(config => logger(config))}
    //console.log("fileTags",state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName));
    if(state.filetags.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1){sendTags(state);}
  }, [state.filetags, state.filetags.map(function(e) { return e.filename; }).indexOf(state.activeFileName)]); // Only re-run the effect if count changes
 
 
 useEffect(() => {
	 //console.log("state:",state);
    if(state.filetags.length>0){sendCntrTags(state);}
  }, [state.LastRecTime]); // Only re-run the effect if count changes
 
 
  useEffect(() => {
    //fetchFiles(state.activeFileName);
	console.log("count=",count);
	if(count==0){
		//console.log("fetching ",state.activeFileName);
		if(state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)===-1 && state.activeFileName!==""){
		fetchFiles(state.activeFileName);
		}
		 navigation.setOptions({title:state.activeFileName.split(".")[0]});
		//console.log("State after fetching ",state);
	}
	if(count==2){
		//console.log("fetching ",state.activeFileName);
		//console.log("state.filetags=",state.filetags);
		sendTagList(state.filetags);
		//console.log("State after fetching ",state);
	}
	if(count==3){
		//console.log("deleting ",state.activeFileName);
		//deleteFileTags(state.activeFileName); 
		deleteFile(state.activeFileName); 
		//console.log("State after deleting ",state);
	}
  }, [count]); // Only re-run the effect if count changes

  useEffect(() => {
	  console.log("str",str);
    //var result = [];
   	var list=[];
	
	var TagName="";
var TagType="";
var TagVal=0;
var stA=0;
var func=1;
  var datalen=0;
        
	//if (/^[0-9a-f]+$/.test(str)){
		for (var i = 0; i < str.length; i += 2) {
    list.push(str.substring(i, i + 2));
}
		func=parseInt(list[1],16);
		datalen=parseInt(list[4], 16);
		stA=parseInt(list[2] + list[3], 16);
		if(func==1 || func==5){
		if(state.tagDB.map(function(e) { return e.Address; }).indexOf(stA)>-1){
			TagName=state.tagDB[state.tagDB.map(function(e) { return e.Address; }).indexOf(stA)].Tag;
			TagType=state.tagDB[state.tagDB.map(function(e) { return e.Address; }).indexOf(stA)].type;
			//console.log(`{"Tag":"${TagName}","type":"${TagType}","Address":${stA},"value":"${TagVal}","sent":0}`);
		}
      if(datalen==4){
		  if(TagType=="R"){
            TagVal=HexToFloat32(list[8]+list[7]+list[6]+list[5]);
		  }
		  if(TagType=="L"){
			TagVal=parseInt(list[8]+list[7]+list[6]+list[5], 16);
		  }
      }else if(datalen==2){
        TagVal=parseInt(list[6] + list[5], 16);
      }else{
        TagVal=parseInt(list[5], 16);
      }
    
	if (TagName!==""){
		  var d = new Date();
		var ob={Tag:TagName,type:TagType,Address:stA,value:TagVal,sent:d.getTime()};
		//console.log(`{Tag:"${TagName}",type:"${TagType}",Address:${stA},value:"${TagVal}",sent:0}`);
		addDBTags(ob);
	} 
	}
	if(func==9){
		console.log("logic=",hex_to_ascii(str.substring(10)));
	}
  }, [str]); // Only re-run the effect if count changes

  

  
  
  const size= useWindowSize();
  const guidelineBaseWidth = 1025;
const guidelineBaseHeight = 716;


const top=(p)=>{
  
  return (((size.window.height-100)*(p)/guidelineBaseHeight)-((size.window.height-100)/2));
}
const left=(p)=>{
 
  return parseInt(((size.window.width)*(p)/guidelineBaseWidth)-((size.window.width)/2)+0);
}
const h=(p)=>{
  
  return ((size.window.height)*p/guidelineBaseHeight);
}
const w=(p)=>{
  
  return parseInt((size.window.width)*p/guidelineBaseWidth);
}
  
  
 /*  const style1 = Object.keys(json).map((key,i) => {
    return { pair:`${key}: ${isNaN(json[key])?json[key]:h(json[key])}` }
});
console.log("style1",style1);
console.log("style1[0]",style1[0].pair); */
  const Counter = () => {
    const increment = useEmitEvent('INCREMENT');
    const decrement = useEmitEvent('DECREMENT');
  
    return (
      <>
        <button onClick={() => decrement()}>Decrement</button>
        <button onClick={() => increment()}>Increment</button>
      </>
    )
  }
  
  const Display = () => {
    //const state = useSocketState();
    const count = useSocketSelector(state => state.count);
    console.log(count);
    if(count==1){
      fetchFiles();
    }
    //console.log(state);
    return (
      <p>{count}</p>
    );
  }
    const pan = useRef(new Animated.ValueXY()).current;

    const panResponder = useRef(
      PanResponder.create({
        onMoveShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
          pan.setOffset({
            x: pan.x._value,
            y: pan.y._value
          });
        },
        onPanResponderMove: Animated.event(
          [
            null,
            { dx: pan.x, dy: pan.y }
          ]
        ),
        onPanResponderRelease: () => {
          pan.flattenOffset();
        }
      })
    ).current;
  
    return (
      <View style={styles.container}>
        {/* <View style={styles.header}>
          <Text style={styles.title}>React Native Zoomable View</Text>
        </View>

        <View style={styles.zoomWrapper}>
          <ReactNativeZoomableView
            zoomEnabled={true}
            maxZoom={1.5}
            minZoom={0.5}
            zoomStep={0.25}
            initialZoom={0.9}
            bindToBorders={true}
            onZoomAfter={this.logOutZoomState}
            style={styles.zoomableView}
          >
             <Image
              style={styles.image}
              source={require('./image.jpg')}
              resizeMode="stretch"
            /> 
            <Text style={styles.caption}>Vienna, Austria</Text>
          </ReactNativeZoomableView>
        </View> */}
      
      {/*   <Text style={{margin: h(1), fontweight: "bold", fontSize: w(5)}}>Drag this box!</Text>
        <Animated.View
          style={{
            transform: [{ translateX: pan.x }, { translateY: pan.y }]
          }}
          {...panResponder.panHandlers}
        >
          <View style={[styles.box, {height: h(5), width: w(5)}]} />
        </Animated.View>
        
        <Counter />
      <Display /> */}
      {/*  <ReactNativeZoomableView
          maxZoom={1.5}
          minZoom={0.5}
          zoomStep={0.5}
          initialZoom={1}
          bindToBorders={true}
          onZoomAfter={this.logOutZoomState}
          style={{
            padding: 10,
            backgroundColor: 'red',
          }}
        > */}
      {/* <Svg height={size.window.height} width={size.window.width} viewBox={`0 0 1025 716`} preserveAspectRatio="none">
      <Rect
            x="0"
            y="0"
            width={1025}
            height={716}
            stroke="black"
            strokeWidth="2"
            fill="none"
          /> */}
          
        {state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1?
	  state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(state.activeFileName)].data.map(config => RenderCard(config)):<View><Text style={{fontSize:20}}>Loading..</Text></View>}
    
      {/* </Svg> */}
{/*       <View style={{position: 'absolute', top: 0, left: 0, justifyContent: 'center', alignItems: 'center'}}>
      <Text>{`Window Dimensions: Theight - ${size.window.height}, Twidth - ${size.window.width} screenwidth= ${size.screen.width} - screenheight=${size.screen.height}`}</Text>
      </View> */}
      {/* </ReactNativeZoomableView> */}
      </View>
    );
  }
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    box: {
      backgroundColor: "blue",
      borderRadius: 5
    },
    header: {
      backgroundColor: '#5569ff',
      paddingTop: 50,
      paddingBottom: 15,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    title: {
      color: '#fff',
      fontSize: 20,
    },
    zoomWrapper: {
      flex: 1,
      overflow: 'hidden',
    },
    zoomableView: {
      padding: 10,
      backgroundColor: '#fff',
    },
    image: {
      flex: 1,
      width: '100%',
      height: '80%',
      marginBottom: 10,
    },
    caption: {
      fontSize: 10,
      color: '#444',
      alignSelf: 'center',
    },
  });
  
  export default PanResponderScreen;