import React from "react";
import { View, StyleSheet } from "react-native";
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import evalExp from './EvalExp';
import Svg, {Text,
 ForeignObject
} from 'react-native-svg';
import {evalColor, evalVisibility, evalFontSize} from './AttribIndex';
/* export default props => (
  
  <View style={{position: 'absolute', top: h(props.config._attr.top), left: w(props.config._attr.left), width:w(props.config._attr.width), height:h(props.config._attr.height)}}>
     <Button labelStyle={{alignItems:'center'}} mode="contained" color={props.config.children[1]._attr.backColor}>{props.config.children[1].children[0]._attr.caption}</Button>
  </View>
); */

const TextComp = (props) => {
  
/*   const x=parseInt(props.config._attr.left) && parseInt(props.config._attr.width) ? parseInt(parseInt(props.config._attr.left) + parseInt(props.config._attr.width/2)) : null;
  const y=parseInt(props.config._attr.height) && parseInt(props.config._attr.top) ? parseInt(parseInt(props.config._attr.top) + parseInt(props.config._attr.height/2)) : null; */
 

  return (
    <>
	<Svg>
{evalVisibility(props)==1?evalColor(props)!==-1?<Text
	  fill={evalColor(props).foreColor1}
	  stroke={evalColor(props).foreColor1}
    fontSize={evalFontSize(props)}
	fontFamily={props.config._attr.fontFamily}
    fontWeight="normal"
    x={parseInt(props.config._attr.left)}
    y={parseInt(props.config._attr.top)}
    textAnchor="start"
	dominantBaseline="hanging"
  >
    {props.config._attr.caption}
	  </Text>:<Text
    fill={props.config._attr.foreColor}
    stroke={props.config._attr.foreColor}
    fontSize={evalFontSize(props)}
    fontWeight="normal"
	fontFamily={props.config._attr.fontFamily}
    x={parseInt(props.config._attr.left)}
    y={parseInt(props.config._attr.top)}
    textAnchor="start"
	dominantBaseline="hanging"
  >
    {props.config._attr.caption}
	  </Text>:<Text></Text>}
</Svg>

    </>
  );
};

export default TextComp;

