import React from "react";
import { View, StyleSheet, Text } from "react-native";
import {Button} from 'react-native-paper';
import Colors from '../../constants/Colors';
import {top, left, w, h} from '../Scale';
import { Popup } from 'semantic-ui-react';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import evalExp from './EvalExp';
import Svg, {
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
} from 'react-native-svg';
/* export default props => (
  
  <View style={{position: 'absolute', top: h(props.config.top), left: w(props.config.left), width:w(props.config.width), height:h(props.config.height)}}>
     <Button labelStyle={{alignItems:'center'}} mode="contained" color={props.config.children[1].backColor}>{props.config.children[1].children[0].caption}</Button>
  </View>
); */

const GroupComponent = (props) => {
  
  
  return (
    <>

{/* {typeof props.config.children!=="undefined" && animationIndex()>-1 && animateVisibilityIndex()>-1?
    evalExp(props.config.children[animationIndex()].children[animateVisibilityIndex()]._attr.expression) && <Svg>{props.children}</Svg>
    :props.config._attr.visible && <Svg>{props.children}</Svg>} */}
    {props.config._attr.visible && <Svg>{props.children}</Svg>}
    </>
  );
};

export default GroupComponent;

