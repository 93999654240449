import React, {useState, useEffect, useContext} from "react";
import { View, TextInput } from "react-native";
import Colors from '../../constants/Colors';
import useWindowSize from '../../hooks/useWindowSize';
import Svg, {
   Rect, ForeignObject
} from 'react-native-svg';

import TouchableOpacityG from '../TouchableOpacityG';
import { Popup } from 'semantic-ui-react';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import evalExp from './EvalExp';
import {evalColor} from './AttribIndex';
import {Float32ToHex, HexToFloat32, convertNumArrayToHexString} from "./float32encoding.js";
import { Context as GfxContext } from '../../context/GfxContext';

  
  
const numericInputComp = (props) => {
  const { state, fetchFiles, addFileTags, deleteFileTags, deleteFile, activateFile, setFileTags, clearFiles, addDBTags, updateTime } = useContext(GfxContext);
const roundN=(num,n) => {
  return parseFloat(Math.round(num * Math.pow(10, n)) /Math.pow(10,n)).toFixed(n);
}
const dval=roundN(evalExp(props.config.children[props.config.children.map((e)=>e.name).indexOf('connections')].children[0]._attr.expression),props.config._attr.decimalPlaces);
const [value, setValue] = useState(evalExp(props.config.children[props.config.children.map((e)=>e.name).indexOf('connections')].children[0]._attr.expression));
const [TagValue, setTagValue] = useState(null);
    const sendToAPI=useEmitEvent('ToAPI');
	
	
	  const sendSetCntrTags=(tlist, tag, val)=>{
		//console.log("cntr tags sent", tlist);
		var k=0;
tlist.tagDB.map(tagDBtag =>{if(tag===tagDBtag.Tag){
	var msg=[];
	k=1;
msg.push(1); //0
if(tagDBtag.type!=="B"){
msg.push(6); //1 // write Analog
}else{
msg.push(2); //1 // set digital
}
msg.push(tagDBtag.Address); //2
if(tagDBtag.type==="R" || tagDBtag.type==="L"){
msg.push(4); //3
}
if(tagDBtag.type==="I"){
msg.push(2); //3
}
if(tagDBtag.type==="B"){
msg.push(1); //3
}
msg.push(val);//4 onwards
console.log("set msg=",convertNumArrayToHexString(msg));
sendToAPI(convertNumArrayToHexString(msg));
}});
if(k==0){
	var ta={};
	ta[tag]=val;
	sendToAPI(ta);
}

}
	
	
    const onChangeText=(text)=>{
      setValue(text);
      console.log('prevalue', text);
    }
    const onSubmit=()=>{
      console.log('Finalvalue', value);
	  setTagValue(value);
      
    }
    useEffect(() => {
      setValue(dval);
    }, [dval]); // Only re-run the effect if count changes
   
   
       useEffect(() => {
		   if(TagValue!==null){
	  var key=props.config.children[props.config.children.map((e)=>e.name).indexOf('connections')].children[0]._attr.expression
	  sendSetCntrTags(state, key.substring(key.indexOf("{")+1,key.indexOf("}")), value);
		   }
    }, [TagValue]); // Only re-run the effect if count changes
   
   
  return (
    <>
        
     <Svg>
     {/* <Svg>
  <Rect x={props.config._attr.left} y={props.config._attr.top} width={props.config._attr.width} height={props.config._attr.height} stroke="black" stroke-width="1" fill="white"/>
      </Svg> */}

      <ForeignObject x={props.config._attr.left} y={props.config._attr.top} width={props.config._attr.width} height={props.config._attr.height}>
              <View style={{alignItems:"center",justifyContent:"center"}}>
    <TextInput style={{width: props.config._attr.width-1,
    height: props.config._attr.height-1,
    padding: 1,
    margin: 1,
    backgroundColor: '#ecf0f1',alignItems:"center",justifyContent:"center"}} onChangeText={(text) => onChangeText(text)} value={value} onSubmitEditing={() => onSubmit(value)}/>
    
              </View>
            </ForeignObject>
      
       {props.children}
       </Svg>
      

    </>
  );
};

export default numericInputComp;