import React, { useContext, useEffect } from 'react';
import { StyleSheet, Text, FlatList, TouchableOpacity, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { ListItem } from 'react-native-elements';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as GfxFilesContext } from '../context/GfxFilesContext';
import {navigate} from '../navigationRef';
const GfxFileListScreen = ({ navigation, route }) => {
  const { state, fetchFileList } = useContext(GfxFilesContext);
  const { state: { user } } = useContext(AuthContext);

  useFocusEffect(
    React.useCallback(() => {
      //alert('Screen was focused');
      fetchFileList();
      // Do something when the screen is focused
      return () => {
        //alert('Screen was unfocused');
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );



  return (
    <>
      {/* <NavigationEvents onWillFocus={fetchTracks} /> */}
  <View><Text>{user}</Text></View>
      <FlatList
      showsVerticalScrollIndicator
        data={state}
        keyExtractor={item => item}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              onPress={() =>{
                navigation.replace('PanResponder', { filename: item });
              }
              }
            >
              <ListItem chevron title={item.split("/")[item.split("/").length - 1].split(".")[0]} />
            </TouchableOpacity>
          );
        }}
      />
    </>
  );
};

GfxFileListScreen.navigationOptions = {
  title: 'GfxFileList'
};

const styles = StyleSheet.create({});

export default GfxFileListScreen;
