import React, { useRef, useEffect, useState } from "react";
import * as d3 from 'd3'
import useWindowSize from '../hooks/useWindowSize';
//import useResizeObserver from "./useResizeObserver";

/**
 * Component that renders a DragData
 */
import { View } from 'react-native';

function DragData({ width, height, data, id = "myDragData" }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const size = useWindowSize();
  const radius=32;
  //const dimensions = useResizeObserver(wrapperRef);

      const svg = d3.select(svgRef.current)
    .attr("width", width)
    .attr("height", height)
    .style("border", "1px solid red");
    const svgContent = svg.select(".content1");
  // will be called initially and on every data change
  useEffect(() => {

    //const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

 const chart =() => {
  // const svg = d3.create("svg")
      // .attr("viewBox", [0, 0, width, height])
      // .attr("stroke-width", 2);

  const circles = d3.range(data.length).map(i => ({
    x: 0.01*data[i] * (width - radius * 2) + radius,
    y: 0.01*data[i] * (height - radius * 2) + radius,
	id: data[i]
  }));
  
  
   var ballBodySvg = svg.selectAll('.chart_group')
                   .data(circles, d => d.id)
                   .join(function(group){
                    var enter = group.append("g").attr("class","chart_group").attr('transform', 'translate(' + 0 + ',' + 0 + ')').call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));
                            enter.append("circle").attr("class","group_circle").attr("cx", d => d.x)
      .attr("cy", d => d.y)
      .attr("r", radius)
      .attr("fill", (d, i) => d3.schemeCategory10[i % 10]);
                            enter.append("text").attr("class","group_text").attr("stroke", "black")
      .attr("fill", "black")
	  .attr("text-anchor",'middle')
	  .attr('dx', radius/2)
      .attr('dy', radius/2)
      .attr("x", (value, index) =>  value.x)
      .attr("y", (value, index) => value.y)
	  .text(value =>value.id);
                            return enter;
                    });
  
  
  function dragstarted(event, d) {
	  
    d3.select(this).raise().attr("stroke", "black");
  }

  function dragged(event, d) {
d3.select(this).select("text")
    .attr("x", d.x = event.x)
    .attr("y", d.y = event.y);
  d3.select(this).select("circle")
    .attr("cx", d.x = event.x)
    .attr("cy", d.y = event.y);
    //d3.select(this).attr('transform', 'translate(' + event.x + ',' + event.y + ')');	
  }

  function dragended(event, d) {

    d3.select(this).attr("stroke", null);
  }

	 
	  
  return ballBodySvg.node();
}
    


svgContent
    .call(chart);


  }, [data]);

  return (
    <React.Fragment>
      <View ref={wrapperRef} style={{ marginBottom: "2rem" }}>
        <svg ref={svgRef}>
          <g className="content1" clipPath={`url(#${id})`}></g>
        </svg>
      </View>
    </React.Fragment>
  );
}

export default DragData;