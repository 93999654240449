import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Button, Input } from 'react-native-elements';
import Spacer from './Spacer';

const EditForm = ({ id, headerText, onSubmit, submitButtonText, onDelete, deleteButtonText }) => {
 
  const [trackName, setTrackName] = useState('');
  return (
    <>
      <Spacer>
        <Text h3>{trackName}</Text>
      </Spacer>
      <Input
        label="New Track Name"
        value={trackName}
        onChangeText={setTrackName}
        autoCapitalize="none"
        autoCorrect={false}
      />
      <Spacer />
      
      <Spacer>
      <View style={{flexDirection:"row", alignItems:"stretch", justifyContent:"space-between"}}>
        <Button
          title={submitButtonText}
          onPress={() => onSubmit(id, trackName)}
        />
        <Button
          title={deleteButtonText}
          onPress={() => onDelete(id)}
        />
        </View>
      </Spacer>
    </>
  );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 16,
    color: 'red',
    marginLeft: 15,
    marginTop: 15
  }
});

export default EditForm;
