import React from "react";
import { View, StyleSheet, Text } from "react-native";
import {Button} from 'react-native-paper';
import Colors from '../../constants/Colors';
import {top, left, w, h} from '../Scale';

import Svg, {
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
} from 'react-native-svg';


const LineComponent = (props) => {
  
  //const points = props.config._attr.line.split(" ").map((p,i) => i%2==0?w(p):h(p)).join(' ');
  const points = props.config._attr.line.split(" ")
  return (
    // <View style={{position: 'absolute', top: top(0), left: left(0)}}>
    <Svg>
    <Line x1={points[0]} y1={points[1]} x2={points[2]} y2={points[3]} stroke={props.config._attr.backColor} strokeWidth={props.config._attr.lineWidth} />
    {props.children}
    </Svg>
    // </View>
  );
};

export default LineComponent;

