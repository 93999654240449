import React, { useRef, useEffect, useState } from "react";
import {
  select,
  scaleLinear,
  line,
  max,
  curveCardinal,
  axisBottom,
  axisLeft,
  zoom,
  zoomTransform,
  sum
} from "d3";
import useWindowSize from '../hooks/useWindowSize';
//import useResizeObserver from "./useResizeObserver";

/**
 * Component that renders a D3Table
 */
import { View } from 'react-native';

function D3Table({ width, height, data, id = "myD3Table" }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const size = useWindowSize();
  //const dimensions = useResizeObserver(wrapperRef);

  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current)
    .attr("width", width)
    .attr("height", height)
	.attr("overflow", "auto")
    .style("border", "1px solid red");
    const svgContent = svg.select(".content1");
    //const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();


    
// Table module ////////////////////////////////////
var Table = function module() {
    var opts = {
        width: 200,
        height: 200,
        margins: {top: 20, right: 20, bottom: 20, left: 20}
    };

    function exports(selection) {
        selection.each(function (dataset) {

            //________________________________________________
            // Data
            //________________________________________________
            var columnLabel = dataset.columnLabel;
            var rowLabel = dataset.rowLabel;
            var value = dataset.value;

            //________________________________________________
            // DOM preparation
            //________________________________________________
            // Size
            var chartW = Math.max(opts.width - opts.margins.left - opts.margins.right, 0.1);
            var chartH = Math.max(opts.height - opts.margins.top - opts.margins.bottom, 0.1);

            // SVG
            var parentDiv = select(this).html('');
            var svg = parentDiv.append('svg').attr('width', opts.width).attr('height', opts.height);
            var visSvg = svg.append('g').attr('class', 'vis-group').attr('transform', 'translate(' + opts.margins.left + ',' + opts.margins.top + ')');
            var tableBodySvg = visSvg.append('g').attr('class', 'chart-group');
            var tableHeaderSvg = visSvg.append('g').attr('class', 'chart-group');
            var rowHeaderSvg = tableHeaderSvg.append('g').attr('class', 'row-header');
            var colHeaderSvg = tableHeaderSvg.append('g').attr('class', 'col-header');

            //________________________________________________
            // Table
            //________________________________________________
            var rowHeaderLevelNum = 1;
            var colHeaderLevelNum = 1;
            var cellH = chartH / (value.length + rowHeaderLevelNum);
            var cellW = chartW / (value[0].length + colHeaderLevelNum);

            // Row header
            var rowHeaderCell = rowHeaderSvg.selectAll('rect.row-header-cell')
                .data(rowLabel);
            rowHeaderCell.join('rect')
                .attr("class",'row-header-cell')
                .attr("width", cellW)
				.attr("height", cellH)
                .attr("x", 0)
                .attr("y", function(d, i){return i * cellH + (cellH * colHeaderLevelNum)})
				.attr("fill",'#eee')
				.attr("stroke",'silver');

            // Row header text

				
				rowHeaderSvg.selectAll('text')
    .data(rowLabel)
    .join('text')
	.attr("class",'row-header-content')
      .attr('x', 0)
      .attr('y', (d,i) => {return i * cellH + (cellH * colHeaderLevelNum)})       // <-- Use yscale instead of manually positioning labels
      .attr('dx', cellW/2)
      .attr('dy', cellH/2)
      .attr('fill', 'black')
      .attr("text-anchor",'middle')
      .text(d => d);
				

            // Col header
            var colHeaderCell = colHeaderSvg.selectAll('rect.col-header-cell')
                .data(columnLabel);
            colHeaderCell.join('rect')
                    .attr("class",'col-header-cell')
                    .attr("width",function(d, i){return Math.max(cellW,7*d.length)})
					.attr("height",cellH)
                    .attr("x", function(d, i){if(i==0){return i * cellW + (cellW * rowHeaderLevelNum)}else{var s=0;for(var k=i;k>0;k--){s=s+Math.max(cellW,7*columnLabel[k-1].length)} return s + (cellW * rowHeaderLevelNum)}})
                    .attr("y", 0)
					.attr("fill",'#eee')
					.attr("stroke",'silver');

            // Col header text


				
								colHeaderSvg.selectAll('text')
    .data(columnLabel)
    .join('text')
	.attr("class",'col-header-content')
      .attr('y', 0)
      .attr('x', function(d, i){if(i==0){return i * cellW + (cellW * rowHeaderLevelNum)}else{var s=0;for(var k=i;k>0;k--){s=s+Math.max(cellW,7*columnLabel[k-1].length)} return s + (cellW * rowHeaderLevelNum)}})       // <-- Use yscale instead of manually positioning labels
      .attr('dx', function(d, i){return Math.max(cellW/2,7*d.length/2)})
      .attr('dy', cellH/2)
      .attr('fill', 'black')
      .attr("text-anchor",'middle')
      .text(d => d);
				
             // Body
            var row = tableBodySvg.selectAll('g.row')
                .data(value)
				.join('g')
                .attr('class', 'cell row')
                .each(function(pD, pI){
                    // Cells
                    var cell = select(this)
                        .selectAll('rect.cell')
                        .data(pD)
						.join('rect')
                        .attr("class",'cell')
						.attr("width",function(d, i){return Math.max(cellW,7*d.toString().length)})
						.attr("height",cellH)
                        .attr("x", function(d, i){if(i==0){return i * cellW + (cellW * rowHeaderLevelNum)}else{var s=0;for(var k=i;k>0;k--){s=s+Math.max(cellW,7*value[pI][k-1].toString().length)} return s + (cellW * rowHeaderLevelNum)}})
                        .attr("y", function(d, i){return pI * cellH + cellH})
						.attr("fill",'white')
						.attr("stroke",'silver')
						
					// Text
                    var celltext = select(this)
						.selectAll('text')
						.data(pD)
						.join('text')
                        .attr("class",'cell-content')
						.attr("width",function(d, i){return Math.max(cellW,7*d.toString().length)})
						.attr("height",cellH)
                        .attr("x", function(d, i){if(i==0){return i * cellW + (cellW * rowHeaderLevelNum)}else{var s=0;for(var k=i;k>0;k--){s=s+Math.max(cellW,7*value[pI][k-1].toString().length)} return s + (cellW * rowHeaderLevelNum)}})
                        .attr("y", function(d, i){return pI * cellH + cellH})
                        .attr("dx", function(d, i){return Math.max(cellW/2,7*d.toString().length/2)})
                        .attr("dy", cellH/2)
						.attr("fill",'black')
						.attr('text-anchor','middle')
                        .text(function(d, i){return d;});
                    
                }); 
        });
    }

    exports.opts = opts;
    createAccessors(exports, opts);
    return exports;
};
  
// Helper function ////////////////////////////////////                       
var createAccessors = function(visExport) {
    for (var n in visExport.opts) {
        if (!visExport.opts.hasOwnProperty(n)) continue;
        visExport[n] = (function(n) {
            return function(v) {
                return arguments.length ? (visExport.opts[n] = v, this) : visExport.opts[n];
            }
        })(n);
    }
};                        
 
// Usage ////////////////////////////////////                        
var dataset = {
    rowLabel: ['A', 'B', 'C', 'D', 'E', 'F'],
    columnLabel: ['Rajat', 'Q', 'R', 'S', 'T', 'U'],
    value: [data,[5,6,8,9,7,9],[89,89,67,45,45]]
};
                        
//var width = 400;
//var height = 300;

var table = Table().width(width).height(height);

svgContent
    .datum(dataset)
    .call(table);


  }, [data]);

  return (
    <React.Fragment>
      <View ref={wrapperRef} style={{ marginBottom: "2rem" }}>
        <svg ref={svgRef}>
          <defs>
            <clipPath id={id}>
              <rect x="0" y="0" width="100%" height="100%" />
            </clipPath>
          </defs>
          <g className="content1" clipPath={`url(#${id})`}></g>
        </svg>
      </View>
    </React.Fragment>
  );
}

export default D3Table;