import React, { useRef, useContext, useState, useEffect  } from "react";

import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';

import { Context as GfxContext } from '../../context/GfxContext';

  

const evalexp = (exp) =>{
	const { state, fetchFiles, addFileTags, deleteFileTags, deleteFile, activateFile, setFileTags, addDBTags } = useContext(GfxContext);

const dataVal = useSocketSelector(st => st.TagData);

  const getMapValue=(key)=>{
	  		var k=0;
			var tval=0;
		state.tagDB.map(tagDBtag =>{
			if(key==tagDBtag.Tag){
				//console.log(`returning ${key}=${tagDBtag.value}`);
				k=1;
				tval= tagDBtag.value;
			}
		});
	if(k==0){
    if(typeof dataVal !== "undefined"){
    if(dataVal.hasOwnProperty(key)){
      return dataVal[key];
    }else{
      return 0;
    }}else{
      return 0;
    }
  }else{
	  return tval;
  }
  }
  
    var i=0;
    var varList=[];
    var tag="";
    var expStart=exp;
    while (exp.indexOf("{")!== -1)
    {
      tag=exp.substring(exp.indexOf("{")+1,exp.indexOf("}"));
      while(exp.includes(tag)){
        exp = exp.replace("{"+tag+"}", "a"+i);
      }
      //console.log(`${tag}=${getMapValue(tag)}`);
      varList.push(getMapValue(tag));
      i++;
    }
	
	exp=exp.toLowerCase();
	exp=exp.replace(/  /gi, " ");
    exp=exp.replace(/  /gi, " ");
    var expBefore=exp;
	//console.log("exp=",exp);
    exp=exp.replace(/[\]}[{]/g, "");
    exp=exp.replace(/if/gi, "if(");
    exp=exp.replace(/and/gi, "&&");
    exp=exp.replace(/or/gi, "||");
    exp=exp.replace(/then/gi, "){return ");
    exp=exp.replace(/  /gi, " ");
    exp=exp.replace(/  /gi, " ");
    exp=exp.replace(/else if/gi, ";} else if");
	if(exp.lastIndexOf('else')!==-1){
    exp=exp.substring(0, exp.lastIndexOf('else')) + ';}else{ return ' + exp.substring(exp.lastIndexOf('else')+4);
    }else{
      exp='{ return ' + exp.substring(exp.lastIndexOf('else'));
    }
    exp=exp+';}';
    exp=exp.replace(/  /gi, " ");
    exp=exp.replace(/  /gi, " ");
    if(exp.includes("+")){
      exp=exp.replace(/a/gi, "+a");
    }
    //console.log(exp);
    //console.log("Evaluating",props.config._attr.name);
  try {
    let args = '...args';
    let body = 'let [a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15] = args;'+exp;
    
    var myFunc = new Function(args, body);
    // console.log("expStart:",expStart);
    //     console.log("expBefore:",expBefore);
    //     console.log("expAfter:",exp);
    // console.log("returned Val=",myFunc(...varList));
    return myFunc(...varList);
  } catch (e) {
      if (e instanceof SyntaxError) {
        //console.log("SyntaxError at ",props.config._attr.name);
         //console.log("expStart:",expStart);
        // console.log("expBefore:",expBefore);
         //console.log("expAfter:",exp);
        // console.log("SyntaxError",e);
        return 0;
      } else {
      console.log("OtherError",e);
      return 0;
    }
  }
}
export default evalexp;