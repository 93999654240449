import React from "react";
import { View, StyleSheet, Text } from "react-native";
import {Button} from 'react-native-paper';
import Colors from '../../constants/Colors';
import {top, left, w, h} from '../Scale';
import TouchableOpacityG from '../TouchableOpacityG';
import { Popup } from 'semantic-ui-react';
import Svg, { Ellipse } from 'react-native-svg';

import evalExp from './EvalExp';
import {evalColor, evalVisibility, evalFontSize, animationIndex, animateColorIndex} from './AttribIndex';
const EllipseComp = (props) => {
  
  const x=parseInt(props.config._attr.left) && parseInt(props.config._attr.width) ? parseInt(parseInt(props.config._attr.left) + parseInt(props.config._attr.width/2)) : null;
  const y=parseInt(props.config._attr.height) && parseInt(props.config._attr.top) ? parseInt(parseInt(props.config._attr.top) + parseInt(props.config._attr.height/2)) : null;
  //const points = props.config._attr.line.split(" ").map((p,i) => i%2==0?w(p):h(p)).join(' ');
  //const points = props.config._attr.path.split(" ").map((p,i)=>{i%2===0?`${p},`:`${p} `}).join('');
 
  const style = {
    borderRadius: 4,
    fontSize:10,
    fontWeight: 'bold',
    color:'white',
    backgroundColor:'black',
  }
  const tag=evalColor(props)!==-1?props.config.children[animationIndex(props)].children[animateColorIndex(props)]._attr.expression:"{}";
  return (
    <Popup
     trigger={
    <Svg>
    <TouchableOpacityG onPress={()=>{console.log("Pressed",tag.substring(tag.indexOf("{")+1,tag.indexOf("}")));}}>
    <Ellipse
    cx={x}
    cy={y}
    rx={props.config._attr.width/2}
    ry={props.config._attr.height/2}
    stroke={animateColorIndex(props) && evalColor(props)!==-1>-1?evalColor(props).foreColor1:props.config._attr.foreColor}
    strokeWidth={props.config._attr.lineWidth}
    fill={animateColorIndex(props)>-1?evalColor(props).backColor1:props.config._attr.backColor}
  />
  </TouchableOpacityG>
  {props.children}
</Svg>}
       position="right top"
       content={tag.substring(tag.indexOf("{")+1,tag.indexOf("}"))}
       size='tiny'
       style={style}
      />

  );
};

export default EllipseComp;

