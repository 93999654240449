import React, { useRef, useContext, useState, useEffect  } from "react";
import { useFocusEffect } from '@react-navigation/native';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import { Animated, View, StyleSheet, PanResponder, Text, TouchableOpacity } from "react-native";
import useWindowSize from '../hooks/useWindowSize';
import RenderCard from "./RenderCard";
import { Context as GfxContext } from '../context/GfxContext';
import Svg, {Rect} from 'react-native-svg';
import {navigate} from '../navigationRef';
//import { ReactNativeZoomableView } from '@dudigital/react-native-zoomable-view';

  const Gfx = (props) => {
  	  const { state, fetchFiles, addFileTags, deleteFileTags, deleteFile, activateFile, setFileTags } = useContext(GfxContext);
  const count = useSocketSelector(state => state.count);
  
  const actualFileName=`${props.filename.replace(/"/gi, "")}.json`;

  const [showPopup, setShowPopup] = useState(false);
  var tags=[];
  function logger(config) {
    if (typeof config._attr !== "undefined") {
    if (typeof config._attr.expression !== "undefined") {
      var sPos=0;
      var exp=config._attr.expression;

      while(sPos!==-1){
        var tag=exp.substring(exp.indexOf("{",sPos)+1,exp.indexOf("}",sPos));
      if(tags.map(function(e) { return e; }).indexOf(tag) < 0){
        tags.push(tag);
        }
        
    //console.log("sPos=",sPos);
    //console.log("tag=",tag);
    sPos=exp.indexOf('{', sPos+1);
  }
    }
	if (typeof config._attr.tag !== "undefined") {
      var sPos=0;
      var exp=config._attr.tag;

      while(sPos!==-1){
        var tag=exp.substring(exp.indexOf("{",sPos)+1,exp.indexOf("}",sPos));
      if(tags.map(function(e) { return e; }).indexOf(tag) < 0){
        tags.push(tag);
        }
        
    //console.log("sPos=",sPos);
    //console.log("tag=",tag);
    sPos=exp.indexOf('{', sPos+1);
  }
    }
  }
      (typeof config.children !== "undefined")
            && config.children.map(c => logger(c))
  }

  const sendToAPI=useEmitEvent('TagList');
    const sendTags=(tlist)=>{
      sendToAPI(tlist);
    }

  useFocusEffect(
    React.useCallback(() => {
      //alert('Screen was focused');
      // console.log("filename req:",filename);
      //console.log(`Gfx Screen: focused`);
      //console.log("navigation details:", navigation);
      fetchFiles(actualFileName);
      //navigation.setOptions({title:actualFileName.split(".")[0]});
      //fetchFiles();
      //console.log(count);
      // Do something when the screen is focused
      return () => {
		  //console.log(`Gfx Screen: Removed and fileCount=${state.filetags.length} and actualFileName=${actualFileName}`);
		  //state.filetags.map(function(e) { console.log("tag file=", e.filename); })
		  
        /* console.log("route details:", route);
        //navigation.setParams({filename:""});
        //{state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data.map(config => logger(config))}
        console.log("fileToRemove",actualFileName);
        console.log("fileList=", state.files);
        console.log("fileIndexToRemove",state.files.map(function(e) { return e.filename; }).indexOf(actualFileName));
    if(state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1){  */

	  deleteFileTags(actualFileName); 
      //sendTags(state.filetags);
    
        //alert('Screen was unfocused');
        //clearFiles();
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );
  useEffect(() => {
    //fetchFiles(actualFileName);
    {state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data.map(config => logger(config))}
    //console.log("fileIndex",state.files.map(function(e) { return e.filename; }).indexOf(actualFileName));
    if(state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1){ 
	
	if(state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data[0].children[0]._attr.displayType!=="replace"){
		addFileTags(actualFileName, tags);
	}else{
		//console.log(`Gfx Screen: setFileTags of file ${actualFileName} Started`);
		setFileTags(actualFileName, tags);
	}
	
	}
  }, [state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)]); // Only re-run the effect if count changes
 
  useEffect(() => {
	  
    //fetchFiles(actualFileName);
    //{state.filetags.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data.map(config => logger(config))}
    //console.log("fileTags",state.files.map(function(e) { return e.filename; }).indexOf(actualFileName));
	
    if(state.filetags.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1){ 
	//console.log("replaceorNot",state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data[0].children[0]._attr.displayType);
	if(state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data[0].children[0]._attr.displayType!=="replace"){
		//sendTags(state.filetags);
		setShowPopup(true);
	}else{
		// var fil=state.activeFileName;
		
		// console.log(`Gfx Screen: activation of file ${actualFileName} Started`);
      // activateFile(actualFileName);
	  // console.log(`Gfx Screen: deleteFileTags of file ${state.activeFileName} Started`);
		// deleteFileTags(fil); 
	}
	}
  }, [state.filetags.map(function(e) { return e.filename; }).indexOf(actualFileName)]); // Only re-run the effect if count changes
 
  const size= useWindowSize();
  if (showPopup) {
    return (
	<View style={styles.container}>
        
        {/* {state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data.map(config => RenderCard(config))} */}
        {state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1?
	  state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)>-1 && state.files[state.files.map(function(e) { return e.filename; }).indexOf(actualFileName)].data.map(config => RenderCard(config)):<View style={{width:150,height:150, alignItems:"center", justifyContent:"center"}}><Text style={{fontSize:20}}>Loading..</Text></View>}
	</View>
    );
  }else{
	  return <View/>;
  }
  }
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    box: {
      backgroundColor: "blue",
      borderRadius: 5
    },
    header: {
      backgroundColor: '#5569ff',
      paddingTop: 50,
      paddingBottom: 15,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    title: {
      color: '#fff',
      fontSize: 20,
    },
    zoomWrapper: {
      flex: 1,
      overflow: 'hidden',
    },
    zoomableView: {
      padding: 10,
      backgroundColor: '#fff',
    },
    image: {
      flex: 1,
      width: '100%',
      height: '80%',
      marginBottom: 10,
    },
    caption: {
      fontSize: 10,
      color: '#444',
      alignSelf: 'center',
    },
  });
  
  export default Gfx;