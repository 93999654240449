import React, { useContext } from 'react';
import { Text, StyleSheet, ActivityIndicator, Platform } from 'react-native';
//import MapView, { Polyline, Circle } from 'react-native-maps';
if (Platform.OS !== 'web') {
  MapView = require('react-native-maps').default;
  Polyline = require('react-native-maps').Polyline;
  Circle = require('react-native-maps').Circle;
}
import { Context as LocationContext } from '../context/LocationContext';

const Map = () => {
  const {
    state: { currentLocation, locations }
  } = useContext(LocationContext);

  if (!currentLocation) {
    return <ActivityIndicator size="large" style={{ marginTop: 200 }} />;
  }

  return (
    <MapView
      style={styles.map}
      initialRegion={{
        ...currentLocation.coords,
        latitudeDelta: 0.01,
        longitudeDelta: 0.01
      }}
    >
      <Circle
        center={currentLocation.coords}
        radius={30}
        strokeColor="rgba(158, 158, 255, 1.0)"
        fillColor="rgba(158, 158, 255, 0.3)"
      />
      <Polyline coordinates={locations.map(loc => loc.coords)} />
    </MapView>
  );
};

const styles = StyleSheet.create({
  map: {
    height: 300
  }
});

export default Map;
