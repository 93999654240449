import { NavigationContainer } from '@react-navigation/native';
import * as React from 'react';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as PaperProvider } from 'react-native-paper';
import { Platform, StatusBar, StyleSheet, View, Text } from 'react-native';
import useCachedResources from './hooks/useCachedResources';
import MainNavigator from './navigation/MainNavigator';


import { Provider as AuthProvider } from './context/AuthContext';
import { navigationRef, isMountedRef  } from './navigationRef';
import { Provider as LocationProvider } from './context/LocationContext';
import { Provider as TrackProvider } from './context/TrackContext';
import { Provider as GfxProvider } from './context/GfxContext';
import { Provider as GfxFilesProvider } from './context/GfxFilesContext';
import { FontAwesome } from '@expo/vector-icons';



export default function App(props) {
  const isLoadingComplete = useCachedResources();
  React.useEffect(() => {
    isMountedRef.current = true;

    return () => (isMountedRef.current = false);
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}
        <PaperProvider>
        <GfxFilesProvider>
        <GfxProvider>
        <TrackProvider>
      <LocationProvider>
        <AuthProvider>
        <NavigationContainer ref={navigationRef}>{<MainNavigator/>}</NavigationContainer>  
        </AuthProvider>
      </LocationProvider>
    </TrackProvider>
    </GfxProvider>
    </GfxFilesProvider>
    </PaperProvider>
    </SafeAreaProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
