import createDataContext from './createDataContext';
import trackerApi from '../api/tracker';
import {navigate} from '../navigationRef';
const gfxFileReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_filelist':
      return action.payload;
    default:
      return state;
  }
};


const fetchFileList = dispatch => async () => {
  try {
    const response = await trackerApi.get('/filelist');
    //console.log("filelistRes:",response.data.files);
    dispatch({ type: 'fetch_filelist', payload: response.data.files })
    
  }catch (err) {
    console.log("filefetchingError:",err.response.data.error);
    //navigate('loginFlow',{screen:'Signin'});
  }
};
export const { Provider, Context } = createDataContext(
  gfxFileReducer,
  { fetchFileList },
  []
);
