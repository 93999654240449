import React, { useState } from 'react';
import { StyleSheet, Image } from 'react-native';
import { Text, Input } from 'react-native-elements';
import { Button } from 'react-native-paper';

import Spacer from './Spacer';

const AuthForm = ({ headerText, errorMessage, onSubmit, submitButtonText }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <>
    <Image
        style={styles.tinyLogo}
        source={require('../assets/images/icon5.png')}
      />
      <Spacer>
        <Text h3>{headerText}</Text>
      </Spacer>
      <Input
        style={{width:350}}
        label="Email"
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
        autoCorrect={false}
      />
      <Spacer />
      <Input
        style={{width:350}}
        secureTextEntry
        label="Password"
        value={password}
        onChangeText={setPassword}
        autoCapitalize="none"
        autoCorrect={false}
      />
      {errorMessage ? (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      ) : null}
      <Spacer>
        <Button mode="contained" color="#008080"
          title={submitButtonText}
          onPress={() => onSubmit({ email, password })}
      >{submitButtonText}</Button>
      </Spacer>
    </>
  );
};

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 16,
    color: 'red',
    marginLeft: 50,
    marginRight: 50,
    marginTop: 15
  },
  tinyLogo: {
    width: 150,
    height: 150,
  }
});

export default AuthForm;
