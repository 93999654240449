import React, {useState, useEffect, useContext} from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
//import {Button} from 'react-native-paper';
import Colors from '../../constants/Colors';
import useWindowSize from '../../hooks/useWindowSize';
import Svg, {
  Text, Rect
} from 'react-native-svg';
import TouchableOpacityG from '../TouchableOpacityG';
import { Popup } from 'semantic-ui-react';
import PanResponderScreen from '../../screens/PanResponderScreen';
import Gfx from '../Gfx';
import {
  SocketProvider,
  useSocketState,
  useSocketSelector,
  useEmitEvent
} from 'react-socket-io-hooks';
import evalExp from './EvalExp';
import {evalColor, evalVisibility, evalFontSize} from './AttribIndex';
import {Float32ToHex, HexToFloat32, convertNumArrayToHexString} from "./float32encoding.js";
import { Context as GfxContext } from '../../context/GfxContext';
//import sendSetCntrTags from "./sendSetCntrTags.js";
//import {top, left, w, h} from '../Scale';


/* export default props => (
  
  <View style={{position: 'absolute', top: h(props.config._attr.top), left: w(props.config._attr.left), width:w(props.config._attr.width), height:h(props.config._attr.height)}}>
     <Button labelStyle={{alignItems:'center'}} mode="contained" color={props.config.children[1]._attr.backColor}>{props.config.children[1].children[0]._attr.caption}</Button>
  </View>
); */

const ButtonComponent = (props) => {
const { state, fetchFiles, addFileTags, deleteFileTags, deleteFile, activateFile, setFileTags, clearFiles, addDBTags, updateTime } = useContext(GfxContext);
  const animateTouchIndex = (props) =>{
    if(typeof props.config.children!=="undefined"){
    return props.config.children.map((e)=>e.name).indexOf('command');
    }else return -1;
  }
  const animateActionIndex = (props) =>{
    if(typeof props.config.children!=="undefined"){
    return props.config.children.map((e)=>e.name).indexOf('action');
    }else return -1;
  }
  const size= useWindowSize();
  const dval=animateActionIndex(props)>-1?evalExp(props.config.children[animateActionIndex(props)]._attr.tag):0;
  const [value, setValue] = useState(animateActionIndex(props)>-1?evalExp(props.config.children[animateActionIndex(props)]._attr.tag):0);
  const [TagValue, setTagValue] = useState(null);
  const sendToAPI=useEmitEvent('ToAPI');
  
  
 	  const sendSetCntrTags=(tlist, tag, val)=>{
		console.log(`set tag ${tag}=${val}`);
		var k=0;
tlist.tagDB.map(tagDBtag =>{if(tag===tagDBtag.Tag){
	var msg=[];
	k=1;
msg.push(1); //0
if(tagDBtag.type!=="B"){
msg.push(6); //1 // write Analog
}else{
msg.push(2); //1 // set digital
}
msg.push(tagDBtag.Address); //2
if(tagDBtag.type==="R" || tagDBtag.type==="L"){
msg.push(4); //3
}
if(tagDBtag.type==="I"){
msg.push(2); //3
}
if(tagDBtag.type==="B"){
msg.push(1); //3
}
msg.push(val);//4 onwards
console.log("set msg=",convertNumArrayToHexString(msg));
sendToAPI(convertNumArrayToHexString(msg));
}});
if(k==0){
	var ta={};
	ta[tag]=val;
	sendToAPI(ta);
}

}
  
  
  const pressInAction=()=>{console.log("Pressed",`{"${props.config.children[animateActionIndex(props)]._attr.tag}":${value}}`);
	if(props.config.children[animateActionIndex(props)]._attr.type==="setToOneOnPressZeroOnRelease"){
		//sendSetCntrTags(props.config.children[animateActionIndex(props)]._attr.tag,1);
		//sendToAPI(`{"${props.config.children[animateActionIndex(props)]._attr.tag}":${1}}`);
		setValue(1);
		setTagValue(!TagValue);
		}
	if(props.config.children[animateActionIndex(props)]._attr.type==="setToZeroOnPressOneOnRelease"){
		setValue(0);
		setTagValue(!TagValue);
		}
	}
  
  const pressOutAction=()=>{console.log("Press Released",`{"${props.config.children[animateActionIndex(props)]._attr.tag}":${value}}`);
  if(props.config.children[animateActionIndex(props)]._attr.type==="toggle"){
	  if(value==1){
		  setValue(0);
		  setTagValue(!TagValue);
	  }else{
		  setValue(1);
		  setTagValue(!TagValue);
	  }
	
  }
   if(props.config.children[animateActionIndex(props)]._attr.type==="setToOneOnPressZeroOnRelease"){
	   setValue(0);
	   setTagValue(!TagValue);
  }
  if(props.config.children[animateActionIndex(props)]._attr.type==="setToZeroOnPressOneOnRelease"){
	  setValue(1);
		setTagValue(!TagValue);
		}
  }
  useEffect(() => {
      setValue(dval);
    }, [dval]); // Only re-run the effect if count changes
	
	
	useEffect(() => {
		if(TagValue!==null){
		if(animateActionIndex(props)>-1){
			var key=props.config.children[animateActionIndex(props)]._attr.tag
	  sendSetCntrTags(state, key.substring(key.indexOf("{")+1,key.indexOf("}")), value);
		}
		}
    }, [TagValue]); // Only re-run the effect if count changes
	
	
  const x=parseInt(props.config._attr.left) && parseInt(props.config._attr.width) ? parseInt(parseInt(props.config._attr.left) + parseInt(props.config._attr.width/2)) : null;
  const y=parseInt(props.config._attr.height) && parseInt(props.config._attr.top) ? parseInt(parseInt(props.config._attr.top) + parseInt(props.config._attr.height/2)) : null;
  return (
  /* 
    <TouchableOpacity Style={{alignItems:'center', 
     justifyContent:'center', backgroundColor:props.config.children[1]._attr.backColor, padding:5, margin:1}} 
     onPress={()=>{console.log("Pressed",props.config.children[1].children[0]._attr.caption);
     console.log(`left=${props.config._attr.left},top=${props.config._attr.top},Sleft=${left(props.config._attr.left)},Stop=${top(props.config._attr.top)},fontsize=${w(props.config.children[1].children[0]._attr.fontSize)},width=${w(props.config._attr.width)},height=${h(props.config._attr.height)}`);}}> */
      
      <Popup
     trigger={
     <Svg>
     {evalVisibility(props)==1 && <TouchableOpacityG onPressIn={()=>{if(animateActionIndex(props)>-1){
	 pressInAction();}}} onPressOut={()=>{if(animateActionIndex(props)>-1){pressOutAction();}}}>
       <Svg>
  <Rect x={props.config._attr.left} y={props.config._attr.top} width={props.config._attr.width} height={props.config._attr.height} stroke={props.config.children[1]._attr.backColor} strokeWidth="2" fill={evalColor(props)!==-1?evalColor(props).backColor1:props.config.children[1]._attr.backColor}/>
  <Text x={x} y={y} fontSize={props.config.children[1].children[0]._attr.fontSize} dominantBaseline="middle" textAnchor="middle" fill="black" fontWeight="bold">{` ${props.config.children[1].children[0]._attr.caption}`}</Text>    
      </Svg>
       </TouchableOpacityG>}
       {props.children}
       </Svg>
       }
       position={props.config._attr.left<size.window.width/2?"right top":"left top"}
       content={typeof props.config.children!=="undefined" && animateTouchIndex(props)>-1?<Gfx filename={props.config.children[animateTouchIndex(props)]._attr.pressAction.substring(props.config.children[animateTouchIndex(props)]._attr.pressAction.indexOf(' ')+1)}/>:()=>{//console.log("pressed button",props.config.children[1].children[0]._attr.caption);
	   }
	   }
       size='tiny'
       on='click'
      />

/*   <View style={{position: 'absolute', top: top(props.config._attr.top+1), left: left(props.config._attr.left+1), backgroundColor:props.config.children[1]._attr.backColor}}>
  <TouchableOpacity Style={{alignItems:'center', 
  justifyContent:'center', backgroundColor:props.config.children[1]._attr.backColor, width:w(props.config._attr.width), 
  height:h(props.config._attr.height), margin:1}} 
  onPress={()=>{console.log("Pressed",props.config.children[1].children[0]._attr.caption);}}>
    <Text style={{fontSize: w(props.config.children[1].children[0]._attr.fontSize)}}>{` ${props.config.children[1].children[0]._attr.caption}`}
    </Text></TouchableOpacity>
</View>  */
  );
};

export default ButtonComponent;

