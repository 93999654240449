import React from "react";
import { View, StyleSheet, Text } from "react-native";

import Svg, {
  Path,
  Polygon,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
} from 'react-native-svg';


const PolygonComp = (props) => {
  
  //const points = props.config._attr.line.split(" ").map((p,i) => i%2==0?w(p):h(p)).join(' ');
  //const points = props.config._attr.path.split(" ").map((p,i)=>{i%2===0?`${p},`:`${p} `}).join('');
  return (
    // <View style={{position: 'absolute', top: top(0), left: left(0)}}>
    <Svg>
  <Polygon
    points={props.config._attr.path}
    fill={props.config._attr.backColor}
    fillOpacity={props.config._attr.backStyle==="transparent"?0:1}
    stroke={props.config._attr.foreColor}
    strokeWidth={props.config._attr.lineWidth}
  />
  {props.children}
</Svg>

    // </View>
  );
};

export default PolygonComp;

